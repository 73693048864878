var helper = { 
    getQueryVariable: function (variable) {

        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }, 
    getQueryStringByName: function (name) {

        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(decodeURIComponent(results[1].replace(/\+/g, " ")));
    }, 
    calculateDistance: function (lat1, lon1, lat2, lon2) {

        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var radlon1 = Math.PI * lon1 / 180;
        var radlon2 = Math.PI * lon2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
         
        return dist;
    },
    breakpoints: function (viewport) {
        var bp = {
            desktop: 960,
            tablet: 600,
            mobileNav: 480,
        };

        return (window.innerWidth > bp[viewport]);
    },
    age: function(d,m,y){

        var today = new Date(),
            thisYear = today.getFullYear(),
            thisMonth = today.getMonth() + 1,
            thisDate = today.getDate();

        var age = thisYear - y;

        if(thisMonth < m || thisMonth === m && d <= thisDate){
            age--;
        }

        return age;
    },
    debounce: function (func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
};

/* Null optional values should not be passed to ga send as subsequent optional values will not register:
"If you want to provide a value only for the second or 3rd optional parameter, you need to pass in undefined for the preceding optional parameter."
https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiEventTracking#_gat.GA_EventTracker_._trackEvent
*/

var gaHelper = {
    trackEvent: function (category, action, opt_label, opt_value, opt_noninteraction) {

        if (opt_label === null) opt_label = undefined;
        if (opt_value === null) opt_value = undefined;

        try {
        /*ga('send', 'event', category, action, opt_label, opt_value, 
        {
          nonInteraction: opt_noninteraction,
          'dimension1':  'New Website Hit',
          'dimension2':  'New Website Session',
          'dimension3':  'New Website User'

        });			*/
        
        } catch (e) {
           // console.log(e);
        }
    }
};

 /* var scrollArrow = {
 	init: function(){

 		$(".homepage-hero").prepend('<span class="homepage-hero__scroll-down"></span>');

 		var $arrow = $('.homepage-hero__scroll-down');

 		function aBounce(element, distance, speed) {
 		    for(i = 0; i < 2000; i++) {
 		        element.animate({bottom: '-='+distance},speed)
 		        .animate({bottom: '+='+distance},speed);
 		    }        
 		}

 		$(window).load(function() {
 			aBounce( $arrow, '10px', 600); 
 		});

 		$(window).scroll(function() {
 		    if ($(this).scrollTop() > 100) {
 		        $arrow.stop(aBounce); 
 		    }
 		});  

 	}
 };

 var homepageHero = {
 	init: function(){

 		var resizeThrottle,
 			that = this;

 		this.applyHeight();
 		this.vAlignedContent();

 		$(window).resize(function(){
 			clearTimeout(resizeThrottle);
 			resizeThrottle = setTimeout( function(){
 				that.applyHeight();
 			    that.vAlignedContent();
 			}, 100 );
 		});

 	},
 	applyHeight: function(){
        
 		var wh = $(window).height(),
 			ww = $(window).width(),
 			ar = 3/4,
 			h = wh < ww * ar ? wh - 10 : ww * ar;

 		$(".js-homepage-hero").height(h);
 	},

 	vAlignedContent: function(){

 		var hh = $(".site-header__top ").height(),
 			wh = $(".homepage-hero").height(),
 			ch = $(".homepage-hero__content").height(),
 			tp = ((wh - ch)/2),
 			position = (tp + hh);

 		$(".homepage-hero__content").css('top', position);

 	}

 };*/

var homepageHero = {
  
  init: function(){

      $('.homepage-hero__content__links ul li').on('click', function() {
            var name = $(this).find('a').text(),
                href = $(this).find('a').attr('href');
           gaHelper.trackEvent("Hero Banner link", "Click", "Current URL: " + window.location.href + ", Name: " + name + ", Link: " + href, null, true);
        }); 


    }

};

var noImageHeader = {

    init: function () {

        var $pH = $('.page-header');

        $pH.addClass('page-header--no-image');

        if ($('.page-header img').length > 0) { 
            $pH.removeClass('page-header--no-image');
        }

    }

};

var liquidHero = {

    init: function () {

        var homeHeroImage = $('.homepage-hero'),
            consultantHeroImage = $('.consultant-header'),
            imageHeader = $('.image-header__image');
        
        homeHeroImage.imgLiquid();

        imageHeader.imgLiquid({
            verticalAlign: '20%'

        });
        
        var resizeHandler = function(){
            homeHeroImage.imgLiquid();
            imageHeader.imgLiquid();
        };

        $(window).resize(resizeHandler);
    } 
};

var awards = {

    init: function () {

        $('.awards-carousel').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 1500,
            speed: 1500,
            cssEase: 'linear',
            responsive: 
            [
                {
                  breakpoint: 960,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: true,
                    speed: 800,
                    slidesPerRow: 2,
                    centerPadding: 0,
                    rows: 1,
                    dots: true
                  }
                },
                {
                  breakpoint: 700,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },

                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ]

        });
    }
};

var insurers = {

    init: function () {

        $('.insurers-carousel').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 1500,
            speed: 1500,
            cssEase: 'linear',
            responsive: 
            [
                {
                  breakpoint: 960,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: true,
                    speed: 800,
                    slidesPerRow: 2,
                    centerPadding: 0,
                    rows: 1,
                    dots: true
                  }
                },
                {
                  breakpoint: 700,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },

                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ]

        });
    }
};

var gallery = {

    init: function () {

        $('.js-gallery__slider').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            dots: true,			  
            speed: 1500,
            autoplaySpeed: 1500,
            cssEase: 'linear',
            responsive: 
            [
                {
                  breakpoint: 960,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    speed: 800,
                    slidesPerRow: 2,
                    centerPadding: 0,
                    rows: 1,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    speed: 800,
                    centerPadding: 5
                  }
                }
            ]

        });
    }
};

var videoLightbox = {

    init: function () {

        var $lightboxLink = $("a[rel^='js-videoLightbox']");

        if (helper.breakpoints("tablet")) {

            $lightboxLink.each( function() {

                var $testimonialLinkHtml = "";
                if ($(this).attr('data-testimonial-url')) {
                    var $testimonialUrl = $(this).attr('data-testimonial-url');
                    $testimonialLinkHtml = '<a class="pp_read_testimonial button button button--teal-rounded" href=" ' + $testimonialUrl + ' ">Read full testimonial</a>';
                }

                $(this).prettyPhoto({
                    social_tools: '',
                    show_title: false,
                    theme: 'light_square',
                    deeplinking: false,
                    overlay_gallery: false,
                    horizontal_padding: 60,
                    default_width: 800,
                    default_height: 500,
                    hideflash: true,
                    wmode: 0,		    	
                    //Default prettyphoto markup apart from adding wrapper classes and removing arrows
                    iframe_markup: '<div class="lightbox__video-wrapper test"><iframe src ="{path}" width="{width}" height="{height}" frameborder="no"></iframe></div>',
                    markup: '<div class="lightbox"><div class="pp_pic_holder"><div class="ppt">&nbsp;</div><div class="pp_top"><div class="pp_left"></div><div class="pp_middle"></div><div class="pp_right"></div></div><div class="pp_content_container"><div class="pp_left"><div class="pp_right"><div class="pp_content"><div class="pp_loaderIcon"></div><div class="pp_fade"><a href="#" class="pp_expand" title="Expand the image">Expand</a><div class="pp_hoverContainer"></div><div id="pp_full_res"></div><div class="pp_details"><div class="pp_nav"><a href="#" class="pp_arrow_previous">Previous</a><p class="currentTextHolder">0/0</p><a href="#" class="pp_arrow_next">Next</a></div><p class="pp_description"></p>{pp_social}' + $testimonialLinkHtml + '<a class="pp_close" href="#">Close</a></div></div></div></div></div></div><div class="pp_bottom"><div class="pp_left"></div><div class="pp_middle"></div><div class="pp_right"></div></div></div></div><div class="pp_overlay"></div>'
                });		
            });			
        }
    }
};

var galleryLightbox = {

    init: function () {

        var $windowWidth = getWindowWidth().width;
        var $lightboxLink = $("a[rel^='prettyPhoto']");

        if ($windowWidth > 599) {

            $lightboxLink.prettyPhoto({
                social_tools: '',
                show_title: false,
                theme: 'light_square',
                deeplinking: false,
                default_width: 800,
                default_height: 500,
                overlay_gallery: false,
                horizontal_padding: 60,
                hideflash: true,
                wmode: 0,
                //Pause / restart slick slider on open / close lightbox
                changepicturecallback: function(){$('.gallery__slider').slick('slickPause');},
                callback: function(){$('.gallery__slider').slick('slickPlay');},
                //Default prettyphoto markup apart from adding wrapper classes
                iframe_markup: '<div class="lightbox__video-wrapper"><iframe src ="{path}" width="{width}" height="{height}" frameborder="no"></iframe></div>',
                markup: '<div class="lightbox"><div class="pp_pic_holder"><div class="ppt">&nbsp;</div><div class="pp_top"><div class="pp_left"></div><div class="pp_middle"></div><div class="pp_right"></div></div><div class="pp_content_container"><div class="pp_left"><div class="pp_right"><div class="pp_content"><div class="pp_loaderIcon"></div><div class="pp_fade"><a href="#" class="pp_expand" title="Expand the image">Expand</a><div class="pp_hoverContainer"><a class="pp_next" href="#">next</a><a class="pp_previous" href="#">previous</a></div><div id="pp_full_res"></div><div class="pp_details"><div class="pp_nav"><a href="#" class="pp_arrow_previous">Previous</a><p class="currentTextHolder">0/0</p><a href="#" class="pp_arrow_next">Next</a></div><p class="pp_description"></p>{pp_social}<a class="pp_close" href="#">Close</a></div></div></div></div></div></div><div class="pp_bottom"><div class="pp_left"></div><div class="pp_middle"></div><div class="pp_right"></div></div></div></div><div class="pp_overlay"></div>'
            });		
        } else {
            $lightboxLink.on("click", function (e) {

                if ($(this).hasClass('js-gallery__video')) {
                    $(this).attr('target','_blank');
                } else {
                    e.preventDefault();	
                }

            });
        }
    }
};

var alphabeticFilter = {

    init: function () {

        var aLetters = $('.js-az-menu a'),
            aContentRows = $('.js-az-accordion-group'),
            aContent = aContentRows.find('.js-accordion__content'),
            aContentLinks = aContent.find('a'),
            $toggles = aContentRows.find('.js-accordion__toggle');

        // Find empty accordion sections and disable them
        aContent.each(function(){ 
            if (jQuery.trim($(this).text()) === "") {
                $(this).parent().addClass('accordion--is-disabled');
            }
        });

        aLetters.on('click',function (e)  {

            e.preventDefault();

            // Smooth scroll
            var target = this.hash;
            var $target = $(target);

            var offset = 0;

            if(helper.breakpoints("desktop")){
                offset = - 250 - $(".site-content").offset().top;
            }

            aContent.removeClass("is-on");

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top + offset
            }, 900, 'swing', function () {
                window.location.hash = target;
                $target.find(".js-accordion__content, .js-accordion__toggle").addClass("is-on");
            });

        });

    } 
};

var siteHeader = {
    init: function () {

        var $siteHeader = $(".js-site-header"),
        throttle;

        if ($siteHeader.length === 0) {
            return;
        }

        if($siteHeader.hasClass("site-header--homepage")) { 
            $siteHeader.on("mouseenter", function () {
                $siteHeader.removeClass("site-header--homepage");
                clearTimeout(throttle);
            }).on("mouseleave", function () {
                clearTimeout(throttle);			

                throttle = setTimeout(function(){
                    $siteHeader.addClass("site-header--homepage");
                },2000);
            });
        }		
    }
};

var siteHeaderSearch = {
    config:{
        $form: $(".js-site-header__main__search"),
        $searchInput: $(".js-site-header__main__search__field"),
        $searchProfile: $(".js-site-header__main__search__profile"),
        $reset: $(".js-site-header__main__search__reset"),
        $prompt: $(".js-site-header__main__search__autocomplete__prompt"),
        $warning: $(".js-site-header__main__search__autocomplete__warning"),
        $results: $(".js-site-header__main__search__autocomplete__results")
    },
    init: function () {

        var that = this;

        var onUpdate = helper.debounce(function(element){

            var value = $(element).val();

            if(value.length > 0) {
                that.config.$reset.addClass("is-on");
            } else {
                that.config.$reset.removeClass("is-on");
            }

            if(value.length < 3) {
                that.config.$warning.addClass("is-on");
                that.config.$prompt.removeClass("is-on");
                that.config.$results.removeClass("is-on");
            } else {
                that.config.$warning.removeClass("is-on");
                that.config.$prompt.removeClass("is-on");
                that.performSearch(value);
                //gaHelper.trackEvent("Header Search", "User typed", window.location.href, value, true);
            }

        },250);

        var onFocus = function(){
            var value = that.config.$searchInput.val();

            if(value.length === 0) { 
                that.config.$warning.removeClass("is-on");
                that.config.$results.removeClass("is-on");
                that.config.$prompt.addClass("is-on");
            }
        };

        var onBlur = function(){
            that.config.$prompt.removeClass("is-on");
            that.config.$warning.removeClass("is-on");
        };


        that.config.$searchInput.on("input", function (e) {
            onUpdate(this);
        }).on("focus", function (e) {
            onFocus();
        }).on("blur", function(e) {
            onBlur();
        });

        that.config.$results.on("click", ".search-autocomplete__keywords li", function (e) {
            e.preventDefault();
            that.config.$searchInput.val($(this).text());
            that.config.$results.removeClass("is-on");
            that.config.$form.submit();
        });

        that.config.$form.on("submit", function (e) {
            var value = $(this).find(that.config.$searchInput).val();
            if(value.length < 3){
                e.preventDefault();
                that.config.$warning.addClass("is-on");
            } else {				
                //gaHelper.trackEvent("Header Search", "User Submitted", window.location.href, value, true);
            }
        });

        that.config.$reset.on("click", function (e) {
            e.preventDefault();
            //gaHelper.trackEvent("Header Search", "User Reset", window.location.href, undefined, true);
            that.config.$searchInput.val("").trigger('focus');
            that.config.$results.removeClass("is-on");
            that.config.$reset.removeClass("is-on");
        });		
    },
    performSearch: function (searchTerm) {

        var that = this;
        var endpoint = apilocation + "SiteSearch/Suggestions?query=" + encodeURIComponent(searchTerm) + "&profile=" + that.config.$searchProfile.val();
        if (mockApi) {
            var mockResults = "{\"keywords\":[\"knee hip ankle shoulder surgery\",\"ankle\",\"ankle arthroscopy\",\"ankle replacement\",\"foot ankle\",\"ankles\",\"total ankle replacement surgery\",\"foot ankle surgery\",\"foot and ankle clinic\",\"ankle fusion surgery\"],\"actionLinks\":[]}";
            that.results(mockResults);
            return;
        }

        $.ajax({
          method: "GET",
          url: endpoint,
          dataType: "json",
        }).done(function(data){
            that.results(data);
        });
    },
    results: function (apiResults) {
        var that = this;

        var resp = $.parseJSON(apiResults);
        // jshint multistr:true

        var templateString = '<div class="search-autocomplete">';
        templateString += '<div class="search-autocomplete__action-links">';
        templateString += '<ul>';
        templateString += '{{#each actionLinks}}';
        templateString += '<li><a href="{{href}}">{{displayText}}</a></li>';
        templateString += '{{/each}}';
        templateString += '</ul>';
        templateString += '</div>';
        templateString += '<div class="search-autocomplete__keywords">';
        templateString += '<ul>';
        templateString += '{{#each keywords}}';
        templateString += '<li><a href="">{{this}}</a></li>';
        templateString += '{{/each}}';
        templateString += '</ul>';
        templateString += '</div>';
        templateString += '</div>';

        var template = Handlebars.compile(templateString);
        var html = template(resp);

        if (resp.actionLinks.length > 0 || resp.keywords.length > 0) {
            that.config.$results.addClass("is-on").html(html);
        } else {
            that.config.$results.removeClass("is-on").html(html);
        }
    }
};

var stickyHeader = {

    

    init: function () {

        var _this = this;

         var siteHeader = $('.js-site-header'),
            siteStickyClass = "is-sticky-header",
            mobileHeader = $(".js-site-header-mobile"),
            mobileStickyClass = "site-header-mobile--sticky",
            siteContainer = $(".site-container");

            if (siteHeader.length === 0) {
                return;
            }

        siteHeader.attr('id', 'js-site-header');	

        siteHeader.removeClass("is-sticky");
        siteContainer.removeClass("is-sticky-header").removeAttr("style");

        // Added to force clearing the sticky nav when the scroll position is 0 as sometimes it gets stuck with the waypoint process.
        window.addEventListener("scroll", function (event) {
            if (this.scrollY == 0) {
                //console.log("Cleared sticky");
                $(".site-header__nav").show();
                siteHeader.removeClass("is-sticky");
                siteContainer.removeClass("is-sticky-header").removeAttr("style");
                $(".treatment-contact-us-bar").css({ 'position': 'relative', 'top': 'auto', 'width': 'auto' });
                $(".anchor").removeClass('is-sticky');
                mobileHeader.removeClass(_this.mobileStickyClass);
            }
        });

        // New sticky nav process
        _this.waypoint = new Waypoint({
            element: $(".site-header__waypoint")[0],
            handler: function (direction) {


                if (direction === "down") {
                    //console.log("Scrolling down");
                    
                    $(".site-header__nav").hide();
                    siteHeader.addClass("is-sticky");
                    siteContainer.addClass("is-sticky-header").css({ paddingTop: siteHeader.find(".site-header__fixed").height() });
                    $(".treatment-contact-us-bar").css({ 'position': 'fixed', 'top': '80px', 'width': '100%' });
                    $(".anchor").addClass('is-sticky');


                    mobileHeader.addClass(_this.mobileStickyClass);
                }
                else if (direction === "up") {
                    //console.log("Scrolling up");

                    $(".site-header__nav").show();
                    siteHeader.removeClass("is-sticky");
                    siteContainer.removeClass("is-sticky-header").removeAttr("style");
                    $(".treatment-contact-us-bar").css({ 'position': 'relative', 'top': 'auto', 'width': 'auto' });
                    $(".anchor").removeClass('is-sticky');

                    mobileHeader.removeClass(_this.mobileStickyClass);
                }



            },
            offset: 0
        });
        // End new sticky nav process


        //if (helper.breakpoints("desktop")) {

        //    var waypoint = new Waypoint({
            
        //        element: $(".site-header__waypoint")[0],

        //        handler: function (direction) {								

        //            if( direction === "down" ){
        //                $(".site-header__nav").hide();
        //                siteHeader.addClass("is-sticky");					
        //                siteContainer.addClass("is-sticky-header").css({ paddingTop: siteHeader.find(".site-header__fixed").height() });

        //                $(".treatment-contact-us-bar").css({ 'position': 'fixed', 'top': '80px', 'width' : '100%' });
        //                $(".anchor").addClass('is-sticky');


        //            } else if (direction === "up") {
        //                $(".site-header__nav").show();
        //                siteHeader.removeClass("is-sticky");
        //                siteContainer.removeClass("is-sticky-header").removeAttr("style");

        //                $(".treatment-contact-us-bar").css({ 'position': 'relative', 'top': 'auto', 'width': 'auto' });
        //                $(".anchor").removeClass('is-sticky');
        //            }				
        //        }			
        //    });
        //} else {
        //    if (mobileHeader.length > 0) {

        //    var waypointMobile = new Waypoint({
            
        //        element: mobileHeader[0],

        //        handler: function (direction) {								
        //            if( direction === "down" ){
        //                mobileHeader.addClass(mobileStickyClass);
        //                siteContainer.css({paddingTop: mobileHeader.height()});	
        //            } else if (direction === "up") {
        //                mobileHeader.removeClass(mobileStickyClass);
        //                siteContainer.removeAttr("style");
        //            }				
        //        }			
        //    });		

        //    }	

        //    if (mobileHeader.length > 0) {
        //    }			
        //}			

        var $toggles = $(".js-toggle-site-header__locations, .js-accordion__cookie");

        $toggles.on('click', function(e) {
            e.preventDefault();
            setTimeout(function(){ Waypoint.refreshAll(); }, 0);
        });

        var $enqBtn = $('.call-back-request');

        $('.call-back-request').on('click', function() {
            $(".site-container").css('paddingTop', '0');
            $(".js-site-header").removeClass('is-sticky');
            waypoint.disable();
        });

        $(".js-enquiry-toggle").on('click', function() {
            waypoint.enable();
        });
    }
   
};


if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

var siteHeaderLocations = {

    init: function(){

        var $toggle = $(".js-toggle-site-header__locations"),
            $target = $(".js-site-header__locations"),
            $header = $('.site-header'),
            $headerTop = $('.site-header__top'),
            $nav = $('.site-nav__bar'),
            $topMenuList = $(".site-header__top-menu__list"),
            $topNav = $(".js-top-nav");

        $toggle.on("click", function (e) {
            e.preventDefault();
            
            if ($(this).hasClass('is-on')) {
                $(this).removeClass('is-on');
                $target.removeClass('is-on');
                $headerTop.removeClass('is-filled');
                $nav.removeClass('is-filled');
                $topMenuList.removeClass("site-header__top-menu__list--select-hospital");
               // $(".site-header__top-menu__item").addClass("site-header__top-menu__item--border");
            } else {
                $topNav.find(".navigation a").removeClass("selected");
                $topNav.find(".content").hide();
                $(this).addClass('is-on');
                $target.addClass('is-on');
                $headerTop.addClass('is-filled');
                $nav.addClass('is-filled');
                $topMenuList.addClass("site-header__top-menu__list--select-hospital");
               // $(".site-header__top-menu__item").removeClass("site-header__top-menu__item--border");

            }

        });


        $topNav.find(".navigation a").on('click',function (e) {
            var rel = $(this).attr('rel');
            $topNav.find(".content").hide();

            $topNav.find(".navigation a").removeClass("selected");
            $(this).addClass("selected");

            $topNav.find(".footer").hide();

            $(rel).show();
        });

        $topNav.find(".body a").on('mouseenter', function() {

            var data = $(this).parent().find(".data");
            var infinityNumber = $(this).parent().find(".InfinityNumber");
            var hospital = $(data).data("hospital");
            var address = $(data).data("address");
            var telephone = infinityNumber.text();
            var footerPanel = $(this).parent().parent().parent().find(".footer");

            $(footerPanel).find(".hospital").text(hospital + ",");
            $(footerPanel).find(".address").html(address);
            var telA = $(footerPanel).find(".telephone > a");
            $(telA).find("span").text(telephone);

            $(footerPanel).show();
        }, function() {
            //$(this).parent().parent().parent().find(".footer").hide();
        });


        $(document).on('click',function (e) {
            if (!($(e.target).closest(".js-site-header__locations").length)) {

                if (!e.target.closest(".locations-button")) {
                    if ($(".js-toggle-site-header__locations").hasClass("is-on")) {
                        $(".js-toggle-site-header__locations").trigger("click");
                    }
                }

            }
        });

    }

};

var callBackRequest = {
    init: function () {

        $('.site-container').attr('id', 'site-container');
        $('.contact-panel .call-back-request').attr('href', '#site-container');

        $(document).on('click', '.call-back-request', function (){
            $('.js-enquiry-toggle').addClass("is-on").children('span').html('Close');
            $('.site-header > .js-enquiry-form').addClass('is-on');
            $('.js-site-nav, .js-search-toggle').addClass('is-hidden');
            $('.call-back-request').addClass('is-selected');
        });

    }
};

var siteHeaderEnquiry = {
    init: function () {

        var text;

        $(".js-enquiry-toggle").on("click", function (e) {

            e.preventDefault();

            var that = this;

            if( $(this).hasClass("is-on") ) {
                $(this).removeClass("is-on").children("span").html(text);
                $(this).children("span").html("Enquire");
                $('.site-header > .js-enquiry-form').removeClass("is-on");
                $(".js-site-nav, .js-search-toggle").removeClass("is-hidden");
                $(".js-site-header").removeClass("is-sticky-override");
                $(".site-header--homepage, .site-nav__bar").removeClass("is-filled");

                gaHelper.trackEvent("Enquiry Form", "Close", undefined, undefined, true);

            } else {

                text = text || $(this).text();

                $("html, body").animate({"scrollTop": 0}, 500, function () {
                    $(that).addClass("is-on").children("span").html($(that).data("closeText"));
                    $('.site-header > .js-enquiry-form').addClass("is-on");
                    $(".js-site-nav, .js-search-toggle").addClass("is-hidden");
                    $(".js-site-header").addClass("is-sticky-override");
                    $(".site-header__top, .site-nav__bar").addClass("is-filled");
                    $(".js-search-form, .js-search-close").removeClass("is-on");	

                });
                
                gaHelper.trackEvent("Enquiry Form", "Open", undefined, undefined, true);

            }

        });

    }
};

var siteHeaderTracking = {

    init: function () {
       // this.globalItems();
        this.locationItems();
        //this.primaryItems();	    
        this.secondaryItems();
        //this.footerItems();
    },

    globalItems: function () {

        $(".site-header__top-menu").on("click", ".site-header__top-menu__item:not(.js-toggle-site-header__locations) ", function (e) {
            var name = $(this).find('a').text();
            gaHelper.trackEvent("Global Navigation", "Click", "Current URL: " + window.location.href + ", Navigation item: " + name, undefined, true);
        });
    },

    locationItems: function () {

        $(".site-header__locations .site-header__locations__list").on("click", "li", function (e) {
            var name = $(this).children('a').text();
            gaHelper.trackEvent("Location Navigation", "Click", "Current URL: " + window.location.href + ", Location item: " + name, undefined, true);
        });
    },

    primaryItems: function () {

        $(".site-header__nav").on("click", ".site-nav__list__item", function (e) {
            var name = $(this).children('a').text();
            gaHelper.trackEvent("Primary Navigation", "Click", "Current URL: " + window.location.href + ", Navigation item: " + name, undefined, true);
        });

        //Mobile
        $(".site-nav-mobile").on("click", ".site-nav-mobile__list__item", function (e) {
            var name = $(this).children('a').text();
            gaHelper.trackEvent("Primary Navigation", "Click", "Current URL: " + window.location.href + ", Navigation item: " + name, undefined, true);
        });
    },

    secondaryItems: function () {		

        $(".site-nav__l1 .site-nav__l2").on("click", ".site-nav__column__item", function (e) {
            var name = $(this).children('a').text();
            gaHelper.trackEvent("Secondary Navigation", "Click", "Current URL: " + window.location.href + ", Navigation item: " + name, undefined, true);
        });

        //Mobile
        $(".site-nav-mobile__l1 .site-nav-mobile__l2").on("click", ".site-nav-mobile__list__item", function (e) {
            var name = $(this).children('a').text();
            gaHelper.trackEvent("Secondary Navigation", "Click", "Current URL: " + window.location.href + ", Navigation item: " + name, undefined, true);
        });
    },

    footerItems: function () {		

        $(".footerlinks").on("click", ".footerlinks__item", function (e) {
            var name = $(this).children('a').text();
            gaHelper.trackEvent("Footer Navigation", "Click", "Current URL: " + window.location.href + ", Navigation item: " + name, undefined, true);
        });       
    }
};

var gpConnectTracking = {

    init: function () {

        //$('a[href="https://www.spirehealthcare.com/gpconnect/"]').on('click', function() {
        //    gaHelper.trackEvent("GP Connect Button", "Click", "Current URL: " + window.location.href, undefined, true);
        //});		
    }
};

var ctaTracking = {

    init: function () {

        $('.button, button').on('click', function() {
            var name = $(this).text(),
                href = $(this).attr('href');
            //gaHelper.trackEvent("Button", "Click", "Current URL: " + window.location.href + ", Name: " + name + ", Link: " + href, null, true);
        });	

        //Enquiry form CTA buttons
        $('.call-to-action--desktop, .call-to-action--mobile-nav').on('click', '.call-to-action__link', function () {
            var name = $(this).text();
            if ($(this).hasClass('call-to-action__link--enquiry')) {
                gaHelper.trackEvent("Enquiry CTA", "Click", "Current URL: " + window.location.href + ", Name: " + name, undefined, true);
            }
            if ($(this).hasClass('call-to-action__link--callback')) {
                gaHelper.trackEvent("Call back CTA", "Click", "Current URL: " + window.location.href + ", Name: " + name, undefined, true);
            }
        });	

        //Mobile enquiry form CTA phone numbers
        //$('.call-to-action--mobile-nav').on('click', '.call-to-action__tel', function () {
        //    var name = $(this).text(),
        //        href = $(this).attr('href');
        //    gaHelper.trackEvent("Telephone number CTA", "Click", "Current URL: " + window.location.href + ", Name: " + name + ", Link: " + href, undefined, false);
        //});	

        $('.js-banner-bar__yes').on('click', function() {
            var name = $(this).text(),
                href = $(this).attr('href');
            gaHelper.trackEvent("Banner Yes CTA", "Click", "Current URL: " + window.location.href + ", Name: " + name + ", Link: " + href, undefined, true);
        });

        $('.js-banner-bar__no').on('click', function() {
            var name = $(this).text(),
                href = $(this).attr('href');
            gaHelper.trackEvent("Banner No CTA", "Click", "Current URL: " + window.location.href + ", Name: " + name + ", Link: " + href, undefined, true);
        });
    }
};

var consultantImgCrop = {
    init: function () {
        $('.consultant-listing__item__image--cropped').imgLiquid({
            horizontalAlign: '74%'
        });
    }

};

// var siteHeaderSearch = {
// 	init: function () {
        
// 		var that = this;

// 		$(".js-search-toggle").on("click", function (e) {
// 			e.preventDefault();
// 			if(!$(".js-search-form").hasClass("is-on")){
// 				$('.site-content').css('padding', '0');
// 				$(".js-site-nav").addClass("is-hidden");
// 				$(".js-search-form, .js-search-close").addClass("is-on");
// 				$(".js-search-form__input").trigger('focus');					
// 			} else {
// 				$(".js-search-form").submit();
// 			}
// 		});

// 		$(".js-search-close").on("click", function (e) {
// 			e.preventDefault();
// 			that.close();
// 		});
// 	},
// 	close: function () {
// 		$(".js-site-nav").removeClass("is-hidden");
// 		$(".js-search-form, .js-search-close").removeClass("is-on");
// 	}
// };


var siteNav = {
    
    init: function () { 

        var that = this;
        
        var mouseTimeout;

        this.megaMenuContainer = $(".site-header__nav");


        $(".nav-link").on("click", function (e) {
            e.preventDefault();

            if ($(this).hasClass('is-on')) {
                that.hideSubNav();
            }
            else {
                that.showSubNav(this);
            }
        });

        $(".mega-menu__container").on("mouseleave", function (e) {
            that.hideSubNav();
        });

        $(document).on("keydown", function (e) {
            if (e.key == "Escape") {
                that.hideSubNav();
            }
        });

        $(".site-nav__l2, .site-nav__l3").siblings("a").on("mouseenter", function () {

            that.showSubNav(this);

            clearTimeout(mouseTimeout);

        }).on("mouseleave", function () {

            mouseTimeout = setTimeout(function () {
                that.hideSubNav();
            }, 500);
        });

        $(".site-nav__l2, .site-nav__l3").on("mouseenter", function () {

            clearTimeout(mouseTimeout);

        }).on("mouseleave", function () {
            mouseTimeout = setTimeout(function () {
                that.hideSubNav();
            }, 500);
        });

        that.selectedStates();

        if ($('#js-hospital-area').length > 0) {
            $('.is-on--child-selected  .site-nav__list > li:first-child').after('<li class="site-nav__list__item is-selected"><a href="/spire-fylde-coast-hospital/treatments/bloodtest/"> > ' + $('h1.heading-l').text().trim() + '</a></li>');
        }     

        this.megaMenuContainer.addClass("js");

    },
    selectedStates: function () {

        var currentPath = window.location.href,
            selectedItem = $(".js-site-nav").find("a[href='" + currentPath + "']").parent(".site-nav__list__item, .site-nav__column__item");

        selectedItem.addClass("is-selected");

        selectedItem.parents(".site-nav__l2").addClass("is-on--child-selected")
        .parent(".site-nav__list__item").addClass("is-selected").children("a").addClass("is-selected");

    },
    hideSubNav: function () {
        $(".primary-navigation").removeAttr("aria-expanded");
        $(".primary-navigation").removeClass('is-on').siblings(".site-nav__l2, .site-nav__l3").removeClass('is-on');
        $(".mega-menu").removeClass('is-on').siblings(".site-nav__l2, .site-nav__l3").removeClass('is-on');
        $(".mega-menu").removeAttr('aria-expanded');
        $(".mega-menu").addClass("aria-hidden", "true");
    },
    showSubNav: function (link) {
        var _target = $(link).attr("aria-controls");
        var _megaMenu = $("#" + _target);

        $(".primary-navigation").removeClass('is-on');
        $(".mega-menu").removeClass('is-on').siblings(".site-nav__l2, .site-nav__l3").removeClass('is-on');
        $(link).addClass('is-on').siblings(".site-nav__l2, .site-nav__l3").addClass('is-on');
        $(link).attr("aria-expanded", "true");

        _megaMenu.find("a").first().trigger('focus');
        _megaMenu.attr("aria-expanded", "true");
        _megaMenu.removeAttr("aria-hidden");
    }

    

};

var matchHeight = {

    init: function () {

        var featGridItem = $('.feature-grid__item__text'),
            recruitmentGridItem = $('.feature-grid--recruitment__item__text'),
            pricedItem = $('.feature-grid__item--priced'),
            imageItem = $('.feature-grid__item--image__text'),
            newsFeedItem = $('.news-feed__item'),
            infoLinks = $('.info-links__item__text'),
            practisingAt = $('.consultant-content__section__practising-at .panel'),
            stepByStepAt = $('.stepbystep-content__section .panel'),
            megaMenuColumn = $('.mega-menu__column'),
            callToActionPanelWrapper = $('.call-to-action-panel__wrapper');
        
            if (featGridItem) featGridItem.matchHeight();
            if (pricedItem) pricedItem.matchHeight();
            if (imageItem) imageItem.matchHeight(); 
            if (newsFeedItem) newsFeedItem.matchHeight();
            if (infoLinks) infoLinks.matchHeight();
            if (recruitmentGridItem) recruitmentGridItem.matchHeight();
            if (practisingAt) practisingAt.matchHeight();
            if (stepByStepAt) stepByStepAt.matchHeight();
            if (megaMenuColumn) megaMenuColumn.matchHeight();
            if (callToActionPanelWrapper) callToActionPanelWrapper.matchHeight();

        $.fn.matchHeight._afterUpdate = function(event, groups) {

            var pIh = pricedItem.height();
            var pHeightCorrection = (pIh + 50 + "px");
            pricedItem.height(pHeightCorrection);
            
   // 			var iIh = imageItem.height();
   // 			var iHeightCorrection = (iIh + 80 + "px");
            // imageItem.height(iHeightCorrection);

            var nIh = newsFeedItem.height();
            var nHeightCorrection = (nIh + 50 + "px");
            newsFeedItem.height(nHeightCorrection);

            var rIh = recruitmentGridItem.height();
            var rHeightCorrection = (rIh + 30 + "px");
            recruitmentGridItem.height(rHeightCorrection);

        };


    }

};

var tabs = {
    init: function () {

        if ($(".js-tabs-disable-first").length == 0) {
            $(".js-tabs .tabs__content").first().addClass("is-on");
        }

        var featGridItem = $('.feature-grid__item__text'),
            pricedItem = $('.feature-grid__item--priced'),
            imageItem = $('.feature-grid__item--image__text');

        $(".js-tabs__nav").on("click", "a", function (e) {
            
            e.preventDefault();

            // update matchHeight grid items on tab's click
            $.fn.matchHeight._update(featGridItem);
            $.fn.matchHeight._update(pricedItem);
            $.fn.matchHeight._update(imageItem);

            var $navItems = $(this).parents(".js-tabs").find(".js-tabs__nav a"),
                $contentItems = $(this).parents(".js-tabs").find(".js-tabs__content"),
                index = $navItems.index(this);

            $navItems.removeClass("is-on").eq(index).addClass("is-on");
            $contentItems.removeClass("is-on").eq(index).addClass("is-on");

        });
    }
};

function alertMe(e) {
    e.preventDefault();
    alert("hello world");
}

var accordion = {

    init: function () {
        $("body").on("click", ".js-accordion__toggle", function (e) {
            e.preventDefault();

            var $content = $(this).parents(".js-accordion").find(".js-accordion__content");

            if ($(this).hasClass("is-on")) {
                $(this).removeClass("is-on");
                $content.removeClass("is-on");
            } else {
                $(this).addClass("is-on");
                $content.addClass("is-on");
            }

        });

        $("body").on("click", ".js-accordion--reverse__toggle", function (e) {
            e.preventDefault();

            var $content = $(this).parents(".js-accordion--reverse").find(".js-accordion--reverse__content");
            var $contentMini = $(this).parents(".js-accordion--reverse").find(".js-accordion--reverse__content--minimised");

            if( $(this).hasClass("is-on") ) {
                $(this).removeClass("is-on");				
                $contentMini.removeClass("is-on");				
                $content.removeClass("is-on");
            } else {
                $(this).addClass("is-on");
                $contentMini.addClass("is-on");
                $content.addClass("is-on");
            }
        });

    }

};

var toggleFix = {
    init: function () {

        var	$accordionX = $(".accordion__header__extra"),
            $accToggle = $('.js-accordion__toggle');

        var toggleFix = {
            init: function () {

                var $windowWidth = getWindowWidth().width;

                if ($windowWidth < 599) {

                    if ($accordionX.length > 0) { 
                        $accordionX.next($accToggle).css('top', '15px');
                    }

                } else if ($windowWidth > 599)  {

                    if ($accordionX.length > 0) { 
                        $accordionX.next($accToggle).css('top', '20px');
                    }

                }

            }
        };	

        toggleFix.init();

        $(window).resize(function(){
            toggleFix.init();
        });

    }

};



var notice = {
    init: function () {
        //cookie handling needs to go here
        $(".js-notice").addClass("is-on");

        $(".js-notice__close").on("click", function (e) {
            e.preventDefault();
            $(this).parents(".js-notice").removeClass("is-on");
        });
    }
};

var dateValidation = {
    init: function () {

    $('.dob-date').combodate({
        minYear: 1915,
        maxYear: 2015,
        value: '20-10-2015',
        smartDays: true
    });
    $('.day, .month, .year').wrap('<div class="select-wrapper"></div>');

    } 
      
};

var locationMap = {
    config: {
        canvas: ".js-location-map__canvas",
        locations: [],
        map: null
    },
    init: function () {

        var that = this;		

        function loadMaps() {
            google.load("maps", "3", {
                callback: function () {
                    if (document.getElementById("js-map-hospital") !== null) {
                        that.initHospitalMap();
                    }
                    else {
                        that.initMap();
                        that.initLocationFinder();
                        that.initSpecialityLocationFinder();
                    }
                },
                other_params: 'key=AIzaSyALpZboQbieD8FePSqE0JK2LdTvHlnAZy4&sensor=true&libraries=places'
            });
        }

        if($(this.config.canvas).length > 0) {

            if( typeof google == 'undefined' || !google.load ) {
                $.getScript( '//www.google.com/jsapi', function() {
                    loadMaps();
                });
            } else {
                loadMaps();
            }
        }		
    },

    initSpecialityLocationFinder: function () {
        var locationOptions = {
            types: ['geocode'],
            componentRestrictions: { country: "gb" }
        };

        var specialityId = 0;
        var specialityIdNode = document.getElementById('SpireSpecialityId');
        if (specialityIdNode != undefined) {
            specialityId = specialityIdNode.value;
        }

        var specialityName = "";
        var specialityNameNode = document.getElementById('SpireSpecialityName');
        if (specialityNameNode != undefined) {
            specialityName = specialityNameNode.value;
        }

        var that = this,
            locationInput = document.getElementById('location-input-speciality'),
            specialityLocationInput = document.getElementById('location-input-speciality'),
            specialityId = specialityId,
            specialityName = specialityName,
            autoComplete = new google.maps.places.Autocomplete(locationInput, locationOptions),
            specialityAutoComplete = new google.maps.places.Autocomplete(specialityLocationInput, locationOptions),
            lat = helper.getQueryStringByName("lat") || "",
            lon = helper.getQueryStringByName("lon") || "";

        var hospitalSelector = $("#select-hospital-speciality");
        hospitalSelector.on('change',function () {
            var hospitalId = $("#select-hospital-speciality").val();
            var specialityId = $("#SpireSpecialityId").val();
            //$("#SpecialityLocationSearch").find(".js-speciality-location-accordions").find(".inner-area").remove();
            $(".js-location-accordions-container").find(".js-speciality-location-accordions").remove();
            that.getAccordionDataBySpeciality(specialityId, null, null, hospitalId);
        });

        //var latlng = null;
        google.maps.event.addListener(specialityAutoComplete, 'place_changed', function () {

            //latlng = specialityAutoComplete.getPlace();

        });

        $("#button-GroupSpecialityLocationsFinder").on('click',function (e) {
            var that = this;
            e.preventDefault();

            $(".js-location-accordions-container").find(".js-speciality-location-accordions").remove();

            var speciality = null;
            if (specialityId != null) {
                speciality = specialityId;
            }

            var speciality = speciality,
                locationEntered = specialityLocationInput.value,
                locationSuggested = specialityAutoComplete.getPlace(),
                latlng;

            if (locationEntered && locationSuggested !== undefined) {

                var url = $(this).parents(".js-finder").data("locations") + "?";
                // check for the hidden variable on the module to say it is a module, if so do not use the module url.
                var isModuleUrl = $("#location-search-module").val();
                if (isModuleUrl != undefined) {
                    if (isModuleUrl != '') {
                        url = isModuleUrl + "?";
                    }
                }

                latlng = locationSuggested.geometry.location;
                lat = latlng.lat();
                lon = latlng.lng();

                var qs = "";
                if (speciality > 0) { qs += "speciality=" + speciality + "&"; }
                if (lat !== "" && lon !== "") { qs += "lat=" + lat + "&lon=" + lon + "&"; }

                qs += locationEntered ? "location=" + locationEntered : "";
                //var treatmentId = $("#input-treatment").val();
                if (qs) {

                    var useDirect = $("#location-speciality-accordions-template").length === 0;

                    if (useDirect) {
                        window.location = url + qs;
                    } else {


                        $(".location-accordions").find(".inner-area").remove();
                        locationMap.getAccordionDataBySpeciality(speciality, lat, lon, "");
                    }

                }

            } else {

                var selectedHospital = $("#select-hospital-speciality");
                if (selectedHospital.val() > 0) {
                    locationMap.getAccordionDataBySpeciality(null, null, null, selectedHospital.val() );
                }
                else {
                    $(this).parents(".js-finder").find("#Error").remove();
                    $(this).parents(".js-finder").find(".inner-area").append("<div id=\"Error\">Please select an option from the locations dropdown.</div>");
                    $("#location-input").trigger('focus');
                    return;
                }
            } 



        });

    },

    initLocationFinder: function () {

        var locationOptions = {
            types: ['geocode'],
            componentRestrictions: {country: "gb"}
        };

        var that = this,
            locationInput = document.getElementById('location-input'),
            treatmentInput = document.getElementById('input-treatment'),
            specialityId = document.getElementById('SpireSpecialityId'),
            autoComplete = new google.maps.places.Autocomplete(locationInput, locationOptions),
            lat = helper.getQueryStringByName("lat") || "",
            lon = helper.getQueryStringByName("lon") || "";

        if(helper.getQueryStringByName("location")){
            locationInput.value = helper.getQueryStringByName("location");
        }

        if(helper.getQueryStringByName("treatment")){
            treatmentInput.value = helper.getQueryStringByName("treatment");
        }

        $("#button-GroupLocationsFinder").on('click',function (e) {
            e.preventDefault();

            var treatment = null;
            if (treatmentInput != null)
            {
                treatment = treatmentInput.value;
            }

            var treatment = treatment,
                locationEntered = locationInput.value,
                locationSuggested = autoComplete.getPlace(),
                latlng;

            if (locationEntered && locationSuggested !== undefined) {

                var url = $(this).parents(".js-finder").data("locations") + "?";
                // check for the hidden variable on the module to say it is a module, if so do not use the module url.
                var isModuleUrl = $("#location-search-module").val();
                if (isModuleUrl != undefined) {
                    if (isModuleUrl != '') {
                        url = isModuleUrl + "?";
                    }
                }

                latlng = locationSuggested.geometry.location;					
                lat = latlng.lat();
                lon = latlng.lng();

                var qs = "";
                if (treatment > 0) { qs += "treatment=" + treatment + "&"; }
                if (lat !== "" && lon !== "") { qs += "lat=" + lat + "&lon=" + lon + "&"; }

                qs += locationEntered ? "location=" + locationEntered : "";
                var treatmentId = $("#input-treatment").val();
                if (qs) {
                   
                    var useDirect = $("#location-accordions-template").length === 0;
               
                    if (useDirect) {
                        window.location = url + qs;                        
                    } else {


                        $('#site-container .js-location-accordions').remove();
                        $('.accordion.js-accordion').remove();
                        locationMap.getAccordionData(treatmentId, lat, lon, "");
                    }
                   
                 }

            } else {
                $(this).parents(".js-finder").find("#Error").remove();
                $(this).parents(".js-finder").find(".inner-area").append("<div id=\"Error\">Please select an option from the locations dropdown.</div>");
                $("#location-input").trigger('focus');
                return;
            }      
        });

        var hospitalSelector = $("#select-hospital");
        hospitalSelector.on('change',function () {
            var hospitalId = $("#select-hospital").val();
            $("#site-container .js-location-accordions").find(".inner-area").remove();
            $("#site-container .js-location-accordions").remove();
            $('.accordion.js-accordion').remove();
            that.getAccordionData(null, null, null, hospitalId);
        });

    },

    initMap: function () {

        var isDraggable = $(document).width() > 480 ? true : false; // If document (your website) is wider than 480px, isDraggable = true, else isDraggable = false

        var that = this,
            locations,
            treatment = (helper.getQueryStringByName("treatment") != false ? "treatment=" + helper.getQueryStringByName("treatment") : ""),
            lat = helper.getQueryStringByName("lat"),
            lng = helper.getQueryStringByName("lon"),
            hos = helper.getQueryStringByName("hos");

        this.config.map = new google.maps.Map($(that.config.canvas)[0], {
            center: {lat: -7.858047, lng: 85.364512},
            zoom: 8,
            scrollwheel: false,
            draggable: isDraggable,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            fullscreenControl: true
        });

       // locationMap.getAccordionData(treatment, lat, lng, hos);
    },

    initHospitalMap: function () {

        var isDraggable = $(document).width() > 480 ? true : false; // If document (your website) is wider than 480px, isDraggable = true, else isDraggable = false

        var that = this,
        locations,
        geocoder = new google.maps.Geocoder();
       
        this.config.map = new google.maps.Map($(that.config.canvas)[0], {
            center: { lat: -7.858047, lng: 85.364512 },
            zoom: 8,
            scrollwheel: false,
            draggable: isDraggable,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            fullscreenControl: true
        });

        $.getScript("/dist/js/libs/infobox_packed.js", function () {
            locations = $.parseJSON(locationMapPoints);
            that.setPoints(locations);
        });
    }, 

    getAccordionDataBySpeciality: function (speciality, lat, lng, hospitalId) {

        var that = this;
        $.getScript("/dist/js/libs/infobox_packed.js", function () {

            var addressurl = "";

            if (lat !== "" && lng !== "") {
                var laturl = "&lat=",
                    lonurl = "&lon=";
                addressurl = laturl + lat + lonurl + lng;
            }

            var treatment = "treatment=";
            if (hospitalId !== "") {
                var hosUrl = "&hospitalId=" + hospitalId;
                addressurl = hosUrl;
            }
            if (speciality > 0) {
                speciality = "&speciality=" + speciality;
            }
            var method = 'CustomApi/GetGroupMapHospitals/?' + treatment + addressurl + speciality;
            if (hospitalId !== "") {
                method = 'CustomApi/GetGroupMapHospitalById/?' + addressurl + speciality;
            }

            $.ajax({
                url: apilocation + method,
                async: true,
                dataType: 'json',
                success: function (json) {

                    var locations = $.parseJSON(json);
                    if (hospitalId == null || hospitalId == "") {
                        that.setPoints(locations);
                    }

                    if (addressurl.length > 0) {
                        $("#js-accordion-key").hide();
                        if (locations != undefined) {
                            if (locations.length > 0) {
                                $("#js-accordion-key").show();
                            }
                        }

                        that.renderSpecialityAccordions(locations);

                    }

                    if (locations.length === 0) {
                        $(".js-location-error").show();
                        $("#js-accordion-key").hide();
                    }

                    if (hospitalId == null || hospitalId == "") {
                        if (lat !== "" && lng !== "") {
                            that.setAccordionDistances(locations, lat, lng);
                        }
                    }

                    //force infinity numbers to refresh after async load
                    __NAS._pplr();

                }
            });
        });

    },

    getAccordionData: function (treatment, lat, lng, hospitalId) {
           function filterLocationsForSpireGP(locations) {
            var spireGPModule = document.getElementById("SpireGPModule");
            if (spireGPModule) {
                locations = $.grep(locations, function (el, idx) { return el.SpireGPEnabled }, false);
            }
            return locations;
        }

        function filterLocationForPaediatrics(locations) {
            var spirePaediatricsModule = document.getElementById("SpirePaediatricsModule");
            if (spirePaediatricsModule) {
                locations = $.grep(locations, function (el, idx) { return el.PaediatricsEnabled }, false);
            }
            return locations; 
        }

        var that = this, geocoder = new google.maps.Geocoder();
        //debugger;

        $.getScript("/dist/js/libs/infobox_packed.js", function () {	

            var addressurl = "";

            if (lat !== null && lng !== null) {
                var laturl = "&lat=",
                    lonurl = "&lon=";
                addressurl = laturl + lat + lonurl + lng;
            }     

            if (hospitalId !== ""){
                var hosUrl = "&hospitalId=" + hospitalId;
                addressurl += hosUrl;
            }
            if (treatment > 0) {
                treatment = "treatment=" + treatment;
            }
            var method = 'CustomApi/GetGroupMapHospitals/?' + treatment + addressurl;
            if (hospitalId !== "") {
                method = 'CustomApi/GetGroupMapHospitalById/?' + (addressurl.indexOf("&") == 0 ? addressurl.substring(1) : addressurl);
            }
           

            $.ajax({
                url: apilocation + method,
                async: true,
                dataType: 'json',
                success: function (json) {

                    var locations = $.parseJSON(json); 
                    if (hospitalId == null || hospitalId == "") {
                        that.setPoints(locations);
                    }
                    locations = filterLocationsForSpireGP(locations);
                    locations = filterLocationForPaediatrics(locations);

                    if (addressurl.length > 0)
                    {
                        $("#js-accordion-key").hide();
                        if (locations != undefined) {
                            if (locations.length > 0) {
                                $("#js-accordion-key").show();
                            }
                        }

                        that.renderAccordions(locations);
                    }                    

                    if (locations.length === 0) {
                        $(".js-location-error").show();
                        $("#js-accordion-key").hide();
                    }

                    if (hospitalId == null || hospitalId == "") {
                        if (lat !== "" && lng !== "") {
                            that.setAccordionDistances(locations, lat, lng);
                        }
                    }

                    //force infinity numbers to refresh after async load
                    __NAS._pplr();

                }
            });
        });

    },

    sortLocationsByDistance: function (locations, userLatLong){

        function sorter(a,b){
            return a.distance - b.distance;
        }

        var latlong = userLatLong.split(";");		

        for (var i = 0; i < locations.length; i++) {
            locations[i].distance = helper.calculateDistance(latlong[0], latlong[1], locations[i].lat, locations[i].lon);
            locations[i].distanceFriendly = locations[i].distance.toFixed(2) + " miles";
        }

        locations.sort(sorter);

        return locations;

    },

    setPoints: function (locations) {

        var markers = [],
            bounds = new google.maps.LatLngBounds(),
            // iconDefault = {
            //     // path: 'M31.618 36.277c-8.99 6.417-21.479 4.331-27.896-4.659s-4.331-21.479 4.659-27.896 21.479-4.331 27.896 4.659 4.331 21.479-4.659 27.896zM16.635 19.328l-2.635-1.547c-2.353-1.405-3.337-2.565-3.337-4.005 0-1.616 1.579-2.776 3.829-2.776 2.906 0 5.511 2.415 5.511 2.415s2.523-2.415 5.504-2.415c2.249 0 3.831 1.16 3.831 2.776 0 1.44-0.984 2.6-3.338 4.005l-2.635 1.547c-1.512 0.873-2.633 1.9-3.365 3.063-0.733-1.163-1.853-2.19-3.366-3.063zM7.605 30.287c1.969 1.475 4.146 2.213 6.465 2.213 2.637 0 4.751-1.101 5.93-2.909 1.177 1.809 3.292 2.909 5.931 2.909 2.318 0 4.496-0.739 6.463-2.213l-1.469-2.771c0 0-0.311 0.247-0.498 0.384-1.334 0.982-2.986 1.545-4.463 1.545-2.141 0-3.757-1.335-3.757-3.162 0-1.475 1.124-2.951 3.162-4.144l2.598-1.581c3.233-1.932 4.533-3.724 4.533-6.288 0-3.759-2.882-6.358-7.061-6.358-1.939 0-3.74 0.512-5.439 1.636-1.7-1.124-3.501-1.636-5.439-1.636-4.181 0-7.061 2.599-7.061 6.358 0 2.564 1.3 4.356 4.533 6.288l2.599 1.581c2.037 1.193 3.161 2.669 3.161 4.144 0 1.827-1.616 3.162-3.758 3.162-1.476 0-3.126-0.562-4.461-1.545-0.186-0.136-0.498-0.384-0.498-0.384l-1.47 2.771z',
            //     // fillColor: '#d73282',
            //     // fillOpacity: 1, 
            //     // scale: 1,			    
            //     // strokeWeight: 0
            //     icon = "/img/map/icons/default.png";
            // },
            that = this,
            icon = '',
            centerCache;

            var userLatLong = $(".js-special-locations").data("userlocation");

            if( typeof userLatLong !== "undefined" && userLatLong.length > 0) {
                locations = this.sortLocationsByDistance(locations,userLatLong);
                locations = locations.slice(0,5);

                $(".js-special-locations").children(".accordion-group").html("");

                this.renderAccordions(locations);

            }


            for (var i = 0; i < locations.length; i++) {

                    //Hack for St George's Park

                    if (locations[i].lat === 52.809662 && locations[i].lon === -1.754637) {
                        icon = "/dist/images/map/icons/perform-sgp.png";
                    } else {
                        //icon = iconDefault;
                        icon = "/dist/images/map/icons/default.png";
                    }

                    //
             
                    locations[i].marker = new google.maps.Marker({
                        position: {
                            lat: locations[i].lat,
                            lng: locations[i].lon
                        },
                        icon: icon,
                        map: that.config.map,
                    });

                    // jshint multistr:true

                    locations[i].modal = new InfoBox({
                        content: "<div class=\"location-map__modal\">\
                        <div class=\"location-map__modal__name heading-s\">"+ locations[i].name + "</div>\
                        <div class=\"location-map__modal__address\">"+ locations[i].address + "</div>\
                        <div class=\"location-map__modal__phone heading-s\">"+ locations[i].phone + "</div>\
                        <div class=\"location-map__modal__url\"><a class=\"button button--new-window button--green-rounded button--small\" href=\""+ locations[i].url + "\">Visit Website</a></div>\
                    </div>",
                        alignBottom: true, 
                        infoBoxClearance: new google.maps.Size(50, 50),
                        //closeBoxURL: "",
                        pixelOffset: new google.maps.Size(50, 0)
                    }); 

                    locations[i].label = new InfoBox({
                        content: "",
                        closeBoxURL: "",
                        pixelOffset: new google.maps.Size(45, 10)
                    });

                    // Attach event listener on close infoBox modals
                    google.maps.event.addListener(locations[i].modal, 'closeclick', function() {  
                        that.config.map.setCenter(centerCache);
                    });  

                    (function (i) {
                        google.maps.event.addListener(locations[i].marker, 'click', function () {
                            
                            centerCache = that.config.map.getCenter();

                            for (var j = 0; j < locations.length; j++) {
                                locations[j].modal.close();
                            }
                            locations[i].modal.open(that.config.map, locations[i].marker)
                        });
                    })(i);

                    bounds.extend(locations[i].marker.position);
            }

            if (locations.length == 1) {
                that.config.map.fitBounds(bounds);
                that.config.map.setZoom(14);
            }
            else {
                that.config.map.fitBounds(bounds);
            }			
        
        for (var i = 0; i < locations.length; i++) {
            locations[i].label.open(that.config.map, locations[i].marker);
        }

        this.config.locations = locations;
    },

    renderSpecialityAccordions: function (locations) {
        var source = $("#location-speciality-accordions-template").html(),
            template,
            context,
            html;

        if (source) {
            template = Handlebars.compile(source);
            context = { locations: locations };
            html = template(context);
            $("#location-speciality-accordions-template").after(html);
            //accordion.init(); // todo: not sure about this call.
        }
    },

    renderAccordions: function (locations) {

        var source = $("#location-accordions-template").html(),
            template,
            context,
            html;

        if(source) {
            template = Handlebars.compile(source);
            context = {locations: locations};
            html    = template(context);				
            $("#location-accordions-template").after(html);
            //accordion.init();
        }
    },

    setAccordionDistances: function (locations, lat, lng) {

        var distances = [];
        that = this;

        if (locations.length === 0)
        {
            $(".js-location-error").show();
        }

        for (var i = 0; i < locations.length; i++) {		  
            distances[i] = helper.calculateDistance(locations[i].lat, locations[i].lon, lat, lng );
        }

        $(".js-location-accordions .js-location-accordions__distance").each(function(i) {
            $(this).html(distances[i].toFixed(1) + " miles");
            $(this).parents(".js-accordion").attr("data-miles", distances[i].toFixed(1));
        });

        var defaultRows = 5;
        var isModuleUrl = $("#location-search-module").val();
        if (isModuleUrl != undefined) {
            if (isModuleUrl != '') {
                var number = $("#numberOfLocationsToShow").val();
                if (number > 0)
                {
                    defaultRows = number;
                }
            }
        }

        var sortedDivs = $(".js-location-accordions").find(".js-accordion").toArray().sort(that.sorter).slice(0, defaultRows);
        
        $(".js-location-accordions .inner-area").empty();

        $.each(sortedDivs, function (index, value) {
            $(".js-location-accordions .inner-area").append(value);
        });

        //accordion.init();

    },

    sorter: function (a, b) {
        return a.getAttribute('data-miles') - b.getAttribute('data-miles');
    }
};

var finder = {
    init: function () {       
       
        var currentLocation = location.href.split("?")[0];

        $("#js-consultant-finder-adv-btn").on('click', function (e) {
            e.preventDefault();
            var advSection = $("#js-consultant-finder-adv-section");
            if (advSection != undefined) {
                advSection.slideToggle();
            }
        });

        $(".button-consultant-picker-js").on('click', function (e) {
            e.preventDefault();
            
            var $consultantFinder = $(this).parents(".input-consultant-picker-js"),
                consultantUrl = $consultantFinder.find(".value-input-consultant-picker-js").val();

            if (consultantUrl !== null && consultantUrl !== 0) {
                window.location = consultantUrl;
            }
            else {
                $consultantFinder.find(".error-input-consultant-picker-js").text("Please select a consultant.");
            }            
        });

        $("#button-profile").on('click', function (e) {
            e.preventDefault();
            var hos = $("#input-hos").val();
            var ty = $("#input-type").val();
            var qs = "";
            if (hos > 0) { qs += "filterbyhospital=" + hos + "&"; }
            if (ty != "") { qs += "filterbytype=" + ty; }
            window.location = "?" + qs;
        });


        $(".js-finder").each(function () {

            //set the finder up

            var type = $(this).attr("data-type");

            var finder = $(this);

            if (type == "consultants") {
                //hospital level
                finder.find("input[name='treatments']").on('change',function () {
                    finder.find("input[name='consultantId']").val("");
                    finder.find("input[name='consultantId']").parent().find(".js-filter-dropdown__toggle").text("Select a consultant");
                    //CONSULTANT BY SPECIALTY
                    finder.find("input[name='specialty']").val("");
                    finder.find("input[name='specialty']").parent().find(".js-filter-dropdown__toggle").text("Select a specialty");
                });

                //CONSULTANT BY SPECIALTY
                finder.find("input[name='specialty']").on('change',function () {
                    finder.find("input[name='consultantId']").val("");
                    finder.find("input[name='consultantId']").parent().find(".js-filter-dropdown__toggle").text("Select a consultant");
                    finder.find("input[name='treatments']").val("");
                    finder.find("input[name='treatments']").parent().find(".js-filter-dropdown__toggle").text("Select a treatment");
                });

                finder.find("input[name='consultantId']").on('change',function () {
                    finder.find("input[name='treatments']").val("");
                    finder.find("input[name='treatments']").parent().find(".js-filter-dropdown__toggle").text("Select a treatment");
                    finder.find("input[name='specialty']").val("");
                    finder.find("input[name='specialty']").parent().find(".js-filter-dropdown__toggle").text("Select a speciality");
                });

                //group level
                finder.find("input[name='postCode']").keydown(function () {
                    finder.find("input[name='hospitals']").val("");
                    finder.find("input[name='hospitals']").parent().find(".js-filter-dropdown__toggle").text("Select a hospital");

                    $treatments = finder.find("input[name='treatments']");
                    $treatments.val("");
                    $treatments.parent().find(".js-filter-dropdown__toggle").text("Select a treatment");

                    $treatments.parents(".js-filter-dropdown").attr("data-url", apilocation + "Finders/SuggestTreatments/?filterByHospital=&filterByTreatmentName=");
                    $Dropdown = $treatments.parents(".js-filter-dropdown");
                    filterDropdown.ajaxRequest($Dropdown, apilocation + "Finders/SuggestTreatments/?filterByHospital=&filterByTreatmentName=", "");
                });

                finder.find("input[name='hospitals']").on('change',function () {
                    finder.find("input[name='postCode']").val("");

                    $treatments = finder.find("input[name='treatments']");
                    $treatments.val("");
                    $treatments.parent().find(".js-filter-dropdown__toggle").text("Select a treatment");

                    $treatments.parents(".js-filter-dropdown").attr("data-url", apilocation + "Finders/SuggestTreatments/?filterByHospital=" + finder.find("input[name='hospitals']").val() + "&filterByTreatmentName=");
                    $Dropdown = $treatments.parents(".js-filter-dropdown");
                    filterDropdown.ajaxRequest($Dropdown, apilocation + "Finders/SuggestTreatments/?filterByHospital=" + finder.find("input[name='hospitals']").val() + "&filterByTreatmentName=", "");
                });



            }
            else if (type == "locations") {
                finder.find("input[name='postCode']").keydown(function () {
                    finder.find("input[name='treatments']").val("");
                    finder.find("input[name='treatments']").parent().find(".js-filter-dropdown__toggle").text("Select a treatment");
                });
                finder.find("input[name='treatments']").on('change',function () {
                    finder.find("input[name='postCode']").val("");
                });
            }
            else if (type == "video-header") {

                finder.find("input[name='treatment']").on('change',function () {

                    finder.find("input[name='postcode']").val("");
                    finder.find("input[name='postcode']").parent().find(".js-filter-dropdown__toggle").text("Enter postcode");

                    finder.find("input[name='hospital']").val("");
                    finder.find("input[name='hospital']").parent().find(".js-filter-dropdown__toggle").text("Select a hospital");

                    finder.find("input[name='consultant']").val("");
                    finder.find("input[name='consultant']").parent().find(".js-filter-dropdown__toggle").text("Select a consultant");

                });

                finder.find("input[name='postcode']").keydown(function () {
                    finder.find("input[name='hospital']").val("");
                    finder.find("input[name='hospital']").parent().find(".js-filter-dropdown__toggle").text("Select a hospital");

                    finder.find("input[name='treatment']").val("");
                    finder.find("input[name='treatment']").parent().find(".js-filter-dropdown__toggle").text("Select a treatment");
                });

                finder.find("input[name='hospital']").on('change',function () {
                    finder.find("input[name='postcode']").val("");

                    finder.find("input[name='treatment']").val("");
                    finder.find("input[name='treatment']").parent().find(".js-filter-dropdown__toggle").text("Select a treatment");
                });

                finder.find("input[name='consultant']").on('change',function () {
                    finder.find("input[name='treatment']").val("");
                    finder.find("input[name='treatment']").parent().find(".js-filter-dropdown__toggle").text("Select a treatment");
                });
            }
            else if (type == "testimonials") {
                finder.find("input[name='consultant']").on('change',function () {
                    finder.find("input[name='treatment']").val("");
                    finder.find("input[name='treatment']").parent().find(".js-filter-dropdown__toggle").text("Select a treatment");
                });

                finder.find("input[name='treatment']").on('change',function () {
                    finder.find("input[name='consultant']").val("");
                    finder.find("input[name='consultant']").parent().find(".js-filter-dropdown__toggle").text("Select a consultant");
                });
            }

        });


        //reset button
        $(".js-finder").on("click", ".js-finder__reset", function (e) {
            e.preventDefault();
            window.location.href = currentLocation;
        });

        $(".js-finder").on("click", ".js-finder__submit", function (e) {
            e.preventDefault();

            //find the type
            var type = $(this).parents(".js-finder").attr("data-type");
            var actionUrl = $(this).parents(".js-finder").find("input[name='actionUrl']").val();
            
            if (type == "treatment-only") {
                var treatment = $(this).parents(".js-finder").find("input[name='treatment-url']").val();
                window.location = treatment;
            }
            else if (type == "consultantsaz") {
                var consultantId = $(this).parents(".js-finder").find("input[name='consultantId']").val();
                location.href = consultantId;
            }
            else if(type == "video-header"){

                var hospital = $(this).parents(".js-finder").find("input[name='hospital']").val();
                var postCode = $(this).parents(".js-finder").find("input[name='postcode']").val();
                var treatment = $(this).parents(".js-finder").find("input[name='treatment']").val();
                var consultant = $(this).parents(".js-finder").find("input[name='consultant']").val();

                if (hospital != "" && typeof hospital != 'undefined') {
                    location.href = hospital;
                } else if (postCode != "" && typeof postCode != 'undefined') {
                    location.href = "/locations/?postcode=" + postCode;
                } else if (treatment != "" && typeof treatment != 'undefined') {
                    location.href = treatment;
                } else if (consultant != "" && typeof consultant != 'undefined') {
                    location.href = consultant;
                }

            }
            else if (type == "appointment")
            {
                var hospital = $(this).parents(".js-finder").find("input[name='hospital']").val();
                var postCode = $(this).parents(".js-finder").find("input[name='postcode']").val();
                var treatment = $(this).parents(".js-finder").find("input[name='treatment']").val();
                var consultant = $(this).parents(".js-finder").find("input[name='consultant']").val();

                if (hospital != "" && typeof hospital != 'undefined') {
                    location.href = hospital;
                } else if (postCode != "" && typeof postCode != 'undefined') {
                    location.href = "/locations/?postcode=" + postCode;
                } else if (treatment != "" && typeof treatment != 'undefined') {
                    location.href = treatment;
                } else if (consultant != "" && typeof consultant != 'undefined') {
                    location.href = consultant;
                }
            }
            else if (type == "consultants") {
                var hospital = $(this).parents(".js-finder").find("input[name='hospitals']").val();
                var treatment = $(this).parents(".js-finder").find("input[name='treatments']").val();
                var postCode = $(this).parents(".js-finder").find("input[name='postCode']").val();
                var consultant = $(this).parents(".js-finder").find("input[name='consultantId']").val();                

                //CONSULTANT BY SPECIALTY
                var speciality = $(this).parents(".js-finder").find("input[name='specialty']").val();

                if (typeof consultant != 'undefined' && consultant != "") {
                    location.href = consultant;
                    return;
                }

                if (typeof hospital == 'undefined') {
                    hospital = "";
                }

                if (typeof treatment == 'undefined') {
                    treatment = "";
                }

                if (typeof postCode == 'undefined') {
                    postCode = "";
                }

                if (typeof consultant == 'undefined') {
                    consultant = "";
                }

                //CONSULTANT BY SPECIALTY
                if (typeof speciality == 'undefined') {
                    speciality = "";
                } 

                var typeSelectedClass = $(this).parents(".js-finder").find(".specialityFilter>ul>li").find("input[name='selector']:checked").attr('class');
                var bookableCheckbox = $("#bookable");
                var bookable = false;
                if (bookableCheckbox.length > 0) {
                    bookable = $("#bookable")[0].checked;
                }

                if (typeSelectedClass == 'treatment') {
                    if (postCode != "" && treatment == "" && hospital == "") {
                        $(this).parents(".js-finder").find("#Error").remove();
                        $(this).parents(".js-finder").find(".inner-area").append("<div id=\"Error\">Please select a treatment to continue.</div>");
                        return;
                    }
                }
                else {
                    if (postCode != "" && speciality == "" && hospital == "") {
                        $(this).parents(".js-finder").find("#Error").remove();
                        $(this).parents(".js-finder").find(".inner-area").append("<div id=\"Error\">Please select a speciality to continue.</div>");
                        return;
                    }
                }                

                //CONSULTANT BY SPECIALTY - 
                location.href = actionUrl + "?filterByTreatment=" + treatment + "&filterBySpecialty=" + speciality + "&filterByHospital=" + hospital + "&filterByName=" + consultant + "&filterByPostCode=" + postCode + "&filterByBookable=" + bookable;
                //location.href = actionUrl + "?filterByTreatment=" + treatment + "&filterByHospital=" + hospital + "&filterByName=" + consultant + "&filterByPostCode=" + postCode;
            }
            else if (type == "testimonials") {
                var treatment = $(this).parents(".js-finder").find("input[name='treatment']").val();
                var consultant = $(this).parents(".js-finder").find("input[name='consultant']").val();

                if (typeof treatment == 'undefined') {
                    treatment = "";
                }
                if (typeof consultant == 'undefined') {
                    consultant = "";
                }

                location.href = actionUrl + "?consultant=" + consultant + "&treatment=" + treatment;
            }
            else if (type == "specialLocations") {
                var postCode = $(this).parents(".js-finder").find("input[name='postCode']").val();

                if (typeof postCode == 'undefined') {
                    postCode = "";
                }

                location.href = "?postCode=" + postCode;
            }
            else if (type == "siteSearch") {
                var siteQuery = $(this).parents(".js-finder").find("input[name='query']").val();

                if (typeof siteQuery == 'undefined') {
                    siteQuery = "";
                }

                location.href = actionUrl + "?query=" + siteQuery;
            }
            else if (type == "hospital-prices") {

                var hospital = $(this).parents(".js-finder").find("input[name='hospital']").val();

                if (hospital != "" && typeof hospital != 'undefined') {
                    location.href = hospital;
                }
            }

            else {
               // console.log("No form action triggered");
            }
            
        }); 

    }
};

function trackCall() {
    gaHelper.trackEvent("Call", "Call made from Website", undefined, undefined, true);
}

var filterDropdown = {
    init: function () {

        var that = this;
        var selectedDefultTreatmentId = $("#defaultgrouptreatment").val();
        var selectedDefultTreatmentText = $("#defaultTreatmentText").val();  

        function selectItem ($dropdown, el) {

                $dropdown.removeClass("is-on");
                $dropdown.parent(".select-wrapper").removeClass("is-open");

                if ($dropdown.data("property") == "url") {
                    $dropdown.find(".js-filter-dropdown__user-selection").val($(el).data("url"));
                }
                else {
                    $dropdown.find(".js-filter-dropdown__user-selection").val($(el).data("id"));
                }

                $dropdown.find(".js-filter-dropdown__user-selection").trigger('change');
                $dropdown.find(".js-filter-dropdown__toggle").html($(el).text());        	

        }        

        $(".js-filter-dropdown").each(function () {

            var $dropdown = $(this),
                $list = $dropdown.find(".js-filter-dropdown__list"),   
                text = $dropdown.find(".js-filter-dropdown__toggle").text(),
                value = $dropdown.find(".js-filter-dropdown__user-selection").val(),
                dataUrl = $dropdown.attr("data-url"),
                url = apilocation + dataUrl,
                searchThrottle,
                oldValue = "";
            if (value == "" && selectedDefultTreatmentId != 0) { value = selectedDefultTreatmentId}         

            if (typeof dataUrl != 'undefined') {
                that.ajaxRequest($dropdown, url, "", value);                 
            }


            $dropdown.on("click", ".js-filter-dropdown__toggle", function (e) {

                e.preventDefault();

                if ($dropdown.hasClass("is-on")) {
                    $dropdown.removeClass("is-on");
                    $dropdown.parent(".select-wrapper").removeClass("is-open");
                } else {
                    $dropdown.parent(".select-wrapper").addClass("is-open");
                    $dropdown.addClass("is-on");
                    $dropdown.find(".js-filter-dropdown__search").trigger('focus');
                    $dropdown.find(".js-filter-dropdown__search-custom").trigger('focus');
                }
            });

            $dropdown.find(".js-filter-dropdown__list").on("click", "li", function () {

                selectItem($dropdown, this);

            });

            $dropdown.find(".js-filter-dropdown__search").on("keyup", function () {

                var $searchInput = $(this),
                    newValue  = $searchInput.val();  

                clearTimeout(searchThrottle);

                searchThrottle = setTimeout(function () {

                    if (newValue !== oldValue) {
                        that.ajaxRequest($dropdown, url, newValue);
                        oldValue = newValue;
                        selectedItem = -1;
                        $list.scrollTop(0);
                    }

                }, 250);

            });

            $dropdown.find(".js-filter-dropdown__search-custom").on("keyup", function () {

                var $searchInput = $(this);
                var $searchInputValue = $searchInput.val().toLowerCase();
                var $noResults = true;
                var $list = $dropdown.find(".js-filter-dropdown__list");
                var $suggestAPI = $dropdown.attr("data-suggest");

                clearTimeout(searchThrottle);

                searchThrottle = setTimeout(function () {

                    if ($searchInputValue == "") {
                        $list.find("li").each(function (index) {
                            $(this).show();
                            $noResults = false;
                        });
                    }
                    else {
                        $list.find("li").each(function (index) {
                            $(this).hide();
                        });

                        $list.find("li[data-name*='" + $searchInputValue + "']").each(function (index) {
                            $(this).show();
                            $noResults = false;
                        });
                    }

                    if ($noResults == true) {
                        $list.find(".js-filter-dropdown__list-error").show();
                    }
                    else {
                        $list.find(".js-filter-dropdown__list-error").hide();
                    }

                }, 250);

            });

            var selectedItem = -1;

            $dropdown.find(".js-filter-dropdown__search, .js-filter-dropdown__search-custom").keydown(function (e) { 


                    var $items = $dropdown.find(".js-filter-dropdown__list li");


                    if(e.which === 40 && selectedItem < $items.length) {            			
                        selectedItem++;
                    } else if (e.which === 38 && selectedItem > -1) {
                        selectedItem--;
                    }

                    if(selectedItem > -1 && selectedItem < $items.length) {

                        $items.removeClass("is-selected");
                        $items.eq(selectedItem).addClass("is-selected"); 

                        $list.scrollTop($items.eq(selectedItem).position().top);

                        if(e.which === 13) {
                            e.preventDefault();
                            selectItem($dropdown,$items[selectedItem]);
                        }
                    }             			
            });

            $list.on("mouseenter", "li", function () {
                $(this).addClass("is-selected").siblings().removeClass("is-selected")
                selectedItem = $(this).index();
            })

           

        });




    },
    ajaxRequest: function ($dropdown, url, searchTerm, selectedValue) {
        $.ajax({
            url: url + searchTerm,
            dataType: "json",
        }).done(function (data) {
            var $list = $dropdown.find(".js-filter-dropdown__list").find("ul");
            $list.html("");
            var d = $.parseJSON(data);
            $.each(d["Suggestions"], function (key, obj) {
                $list.append('<li data-id="' + obj.NodeId + '" data-url="' + obj.Url + '">' + obj.Display + '</li>');
            });
            
            if (d["Error"] != "") {
                var decoded = $('<div/>').html(d["Error"]).text();
                $list.html(decoded);
                // AH: enquiry was always undefined. can't locate what enquiry.init does?
                   // enquiry.init();
                
            }


            if (selectedValue != "") {
                $dropdown.find(".js-filter-dropdown__toggle").html($dropdown.find("[data-id*='" + selectedValue + "']").html());
            }	
                    
        })
    }
};

var newsFilterSelect = {
    init: function () {

        $('.pagination__filter .select-wrapper__select').selectric({
            disableOnMobile: false,
            nativeOnMobile: false
        });
    }
};

function getWindowWidth() {
  var e = window, a = 'inner';
  if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
  }
  return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
};

var paginationFilters = {
    init: function () {
        var that = this;
        $(".js-pagination__filter__select").each(function () {

            var $dropdown = $(this);
            $dropdown.find(".select-wrapper__select").on('change', function () {
                var currentLocation = location.href.split("?")[0];
                var attributeValue = $(this).attr('data-filter-value');
                if (attributeValue === undefined) {
                    attributeValue = "type";
                }

                location.href = currentLocation + "?" + attributeValue + "=" + $(this).find("option:selected").val();
            });
        });
    }
};

var externalLinkClass = {
    init: function () {

        $('.full-width-text a.button--lightmagenta[target="_blank"]').addClass('icon-new-window').css('paddingRight', '30px');
        $('.brand-text-block a.button--lightmagenta[target="_blank"]').addClass('icon-new-window').css('paddingRight', '30px');
        $('.centred-text a.button--lightmagenta[target="_blank"]').addClass('icon-new-window').css('paddingRight', '30px');

    }

};


var cookies = {
    cookieKey: 'cookieAgreementSpire',
    //cookieKey2: 'cookieStayOnTheWebsite',
    init: function () {
        var that = this;


        if (!that.readCookie(that.cookieKey)) {
            $('.js-cookie-bar').addClass('show');
        }
        else {
            $('.js-cookie-bar').removeClass('show');
        }

        $('.js-cookie-bar-agree').on("click", function (e) {
            e.preventDefault();
            that.writeCookie(that.cookieKey, "1", 30);
            $('.js-cookie-bar').removeClass("show");
        });

    },

    bannerCookies: function () {
        var that = this;
        var cookieKeyBase = 'collapseBanner';

        $( ".topAdvert-banner" ).each( function () {

            var bannerId = $(this).data( "bannerid" ); 
            var cookieKey = cookieKeyBase + bannerId;
            var cookie = that.readCookie(cookieKey);
            var $bannerContent = $(this).find('.js-accordion--reverse__content');
            var $bannerToggle = $(this).find('.js-accordion--reverse__toggle');
            var $bannerCTA = $(this).find('.button');
            var bannerMaximised = false;

            if (!cookie) {

                $bannerContent.addClass('is-on');
                $bannerToggle.addClass('is-on');
                bannerMaximised = true;
                    
                gaHelper.trackEvent("Banner-"+bannerId, "Banner Displayed Maximised", undefined, undefined, true);			


            } else{

                $bannerContent.removeClass('is-on');
                $bannerToggle.removeClass('is-on');
                $('.js-accordion--reverse__content--minimised').removeClass('is-on');	

                bannerMaximised = false;
                
                gaHelper.trackEvent("Banner-"+bannerId, "Banner Displayed Minimised", undefined, undefined, true);
            }

            $bannerToggle.on("click", function (e) {
                if(bannerMaximised){
                    if (cookie === null) {
                        that.writeCookie(cookieKey, true , 1);
                    }
                    gaHelper.trackEvent("Banner-"+bannerId, "User Minimised Banner");
                } else {				
                    gaHelper.trackEvent("Banner-"+bannerId, "User Maximised Banner");
                }
            });

            $bannerCTA.on("click", function (e) {
                if($bannerToggle.hasClass("is-on")){
                    gaHelper.trackEvent("Banner-"+bannerId, "User Clicked CTA - Banner Maximised");
                } else {				
                    gaHelper.trackEvent("Banner-"+bannerId, "User Clicked CTA - Banner Minimised");
                }
            });
        });	
    },

     cookieKeySurvey: 'closeEnquiery',
     surveyCookies: function () {

         var that = this;

        var desktopSurveyMaxCount = $("#desktopSurveyMaxCount").val();
        var mobileSurveyMaxCount = $("#mobileSurveyMaxCount").val();

         // if survey is disabled forget it
        if (desktopSurveyMaxCount == -1 && mobileSurveyMaxCount == -1) return;

        var $surveyModal = $(".js-modal--survey");
        var cookie = that.readCookie(that.cookieKeySurvey);

         // if the cookie is not there
        if (cookie === null) {

            // decide on max value dependant on desktop/mobile
            var surveyMaxCount = -1;
            var isDesktop = true;

            if ($(".site-header").is(':visible')) {
                isDesktop = true;
                surveyMaxCount = desktopSurveyMaxCount;
            }
            else {
                isDesktop = false;
                surveyMaxCount = mobileSurveyMaxCount;
            }

            // get the counter i.e. should we show the survey ?
            $.ajax({
                url: apilocation + 'SurveyApi/ShowSurvey',
                method: "GET",
                dataType: "json",
                data: {
                    "surveyMaxCount": surveyMaxCount,
                    "isDesktop": isDesktop
                }
            }).done(function (data) {

                if (data === true) {

                    // write the cookie so this person does not see again for 30 days
                    that.writeCookie(that.cookieKeySurvey, "1", 30);

                    // incrment the relevant counter
                    $.ajax({
                        url: apilocation + 'SurveyApi/' + (isDesktop ? "IncrementDesktopSurveyCount" : "IncrementMobileSurveyCount"),
                        method: "POST",
                    }); 

                    // show the survey in a while
                    setTimeout(function () {
                        $surveyModal.addClass("is-on");
                    }, 15000);

                }

            }); 

        } 

        $surveyModal.on("click", ".js-enquiry-form-modal__close", function (e) {
            $(this).parents(".js-modal").removeClass("is-on");
        });
        $surveyModal.on("click", ".js-enquiry-form__give-feedback", function (e) {

            //get the page url the user have been to before click the "Give feedback" button
            var firstPage = window.location.href;;
            localStorage.setItem("firstPage", firstPage);
            var storedfirstPage = localStorage.getItem('firstPage');


            $(this).parents(".js-modal").removeClass("is-on");
            //var myWindow = window.open("", "_blank", "toolbar=no,scrollbars=yes,resizable=yes,top=0,left=0,width='700',height=700" );
            var modalUrl = location.protocol + '//' + location.host + '/please-wait.html'
            var myWindow = window.open(modalUrl, "myWindow", "scrollbars=1,menubar=1,resizable=1,width=660,height=700");   // Opens a new window
            ;
        });


     },


    writeCookie: function (name, value, days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
        var cookieString = name + "=" + value + expires + "; path=/";
        document.cookie = cookieString;
    },

    readCookie: function (name) {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(name + "=") == 0) {
                return cookie.substring(name.length + 1, cookie.length);
            }
        }
        return null;
    }
};

var enquiryFormCTA = {
    init: function(){

        var that = this;
        that.characterCount();

        $(".js-modal").on("click", ".js-enquiry-form__close", function (e) {
            e.preventDefault();
            $(".js-modal").removeClass("is-on");
            $("body").removeClass("is-body-modal");

            if ($('.enquiry-form__thankyou__content').length > 0) {
                $('.enquiry-form__thankyou__content').remove();
                $('.enquiry-form__content').show();
                $(".enquiry-form-new").trigger('reset');
            }
        });

        //$(".js-enquiry-form__tabs__link--enquiry").on('click', function () {
        //    gaHelper.trackEvent("Enquire tabs", "Click", 'Request an enquiry', undefined, true);
        //});

        //$(".js-enquiry-form__tabs__link--callback").on('click', function () {
        //    gaHelper.trackEvent("Enquire tabs", "Click", 'Request a call back', undefined, true);
        //});


    },
    characterCount: function () {
        var that = this;
        var maxCount = $("#enquiry").attr("data-parsley-maxlength");
        var counter = 0;
        var countText = ""; 

        $("#enquiry").on('keyup', function (e) {
            if ($(".js-enquiry-textarea").length > 0) {
                counter = ($(".js-enquiry-textarea").val().length - 1) + 1;
                if (counter > maxCount) {
                    countText = "<label class='textarea--counter--error'>" + counter + "/" + maxCount + "</label>";
                }
                else {
                    countText = "<label>" + counter + "/" + maxCount + "</label>";
                }
                $(".js-enquiry-textarea-count").html(countText);
            }
        });
    }
};



//var noOfPages =0;
var checkIfWesbiteLeft = {

    init: function () {

        var localLink = false;
        var resetLocalLink;
        var storedTime = localStorage.getItem('time');
        var dateNow = new Date().getTime();

        if (!storedTime) {
            localStorage.setItem("time", dateNow);
        }

        $("body").on("click", "a", function () {
            if (!(this.hostname && this.hostname !== location.hostname)) {
                localLink = true;
            }
            clearTimeout(resetLocalLink);
            resetLocalLink = setTimeout(function () {
                localLink = false;
            }, 10000);
        });

        //count the no of page user have been to (including the refreshes)
        if (localStorage.clickcount) {
            localStorage.clickcount = Number(localStorage.clickcount) + 1;
        } else {
            localStorage.clickcount = 1;
        }

        $(window).on("beforeunload", function () {
            if (!localLink) {
                //get the latest page the user have been to before exiting the website
                var lastPage = window.location.href;
                localStorage.setItem("lastPage", lastPage);
                var storedlastPage = localStorage.getItem('lastPage');
            }
        });
    }

};


var enquiryTabs = {
    init: function () {

        $(".js-enquiry-form__tabs .tabs__content").first().addClass( "is-on");

        var featGridItem = $('.feature-grid__item__text'),
            pricedItem = $('.feature-grid__item--priced'),
            imageItem = $('.feature-grid__item--image__text');

        $(".js-enquiry-form__tabs .enquiry-form__tabs__link").on("click", function (e) {
            
            e.preventDefault();

            var $navItems = $(this).parents(".js-enquiry-form__tabs").find(".js-enquiry-form__tabs__nav a"),
                $contentItems = $(this).parents(".js-enquiry-form__tabs").find(".js-enquiry-form__tabs__content"),
                index = $navItems.index(this);

            $navItems.removeClass("is-on").eq(index).addClass("is-on");
            $contentItems.removeClass("is-on").eq(index).addClass("is-on");

        });
    }
};



var requestForm = {
    init: function () {

        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        var that = this;
        $(".js-request-form__content__requesting-personal-for-someone-else").hide();
        $(".js-full-patient-record").hide();
        $(".js-request-form").hide();

        if ($(".request-form").length > 0) {
            $(".finder--locations").hide();
        }

        $(".js-medicalRecordsAndPersonal").on('change', function () {
            $(".js-request-form").show();
            $(".finder--locations").hide();
            $(".js-medical-records-only-note").removeClass("is-on");
        });

        $(".js-medicalRecords").on('change', function () {
            $(".js-request-form").hide();
            $(".finder--locations").show();
            $(".js-medical-records-only-note").addClass("is-on");
        });

        $("#forSomeoneElse").on('change', function () {
            $(".js-request-form__content__requesting-personal-for-someone-else").show();
            $("#authorisedPerson").prop("disabled", false);
            $("#authorisedPerson").prop("checked", false);
        });

        $("#forMyself").on('change', function () {
            $(".js-request-form__content__requesting-personal-for-someone-else").hide();
            $("#authorisedPerson").prop("disabled", true);
            $("#authorisedPerson").prop("checked", false);
        });

        $("#patientRecordN").on('change', function () {
            $(".js-full-patient-record").show();
        });

        $("#patientRecordY").on('change', function () {
            $(".js-full-patient-record").hide();
        });

        $("#currentPerson").on('change', function () {
            var nowDate = new Date();
            var date = nowDate.getDate() + '/' + month[nowDate.getMonth()] + '/' + nowDate.getFullYear(); 

            $(".js-declaration-full-name").text($("#first-name").val() + " " + $("#surname").val());
            $(".js-declaration-date").text(date);
        });

        $("#authorisedPerson").on('change', function () {

            var nowDate = new Date();
            var date = nowDate.getDate() + '/' + month[nowDate.getMonth()] + '/' + nowDate.getFullYear(); 
            $(".js-declaration-full-name").text($("#first-name-se").val() + " " + $("#surname-se").val());
            $(".js-declaration-date").text(date);
        });

        $(".js-request-form__submit").on('click', function (e) {
            e.preventDefault();
            var thisForm = $(this).parents(".js-request-form");
            that.submitForm(thisForm);
        });
    },

    submitForm: function (thisForm) {

        var $submitButton = $(thisForm).find(".js-request-form__submit");
        var $spinner = thisForm.find('.request-form__button-container__spinner');

        var parsleyConfig = {
            errorsContainer: function (pEle) {
                var $err = pEle.$element.parents('.js-request-form__content__element').find('.error');
                //console.log($err);
                return $err;
            },
            classHandler: function (el) {
                return el.$element.closest('.js-request-form__content__element');
            },
            uiEnabled: true
        };

        var form = $(thisForm).parsley(parsleyConfig);

        if (form[0].validate()) {
            // Dates
            var dobDay = false;
            var dobMonth = false;
            var dobYear = false;

            var dobDayse = false;
            var dobMonthse = false;
            var dobYearse = false;

            var tpFromDay = false;
            var tpFromMonth = false;
            var tpFromYear = false;

            var tpToDay = false;
            var tpToMonth = false;
            var tpToYear = false;

            var personalDataRequest = '';
            var title = '';
            var surname = '';
            var firstname = '';
            var maidenname = '';
            var gender = '';
            var dob = '';
            var emailAddress = '';
            var phoneNumber = '';
            var address = '';
            var postcode = '';
            var titlese = '';
            var surnamese = '';
            var firstnamese = '';
            var maidennamese = '';
            var genderse = '';
            var dobse = '';
            var proofAuth = '';
            var emailAddressse = '';
            var phoneNumberse = '';
            var addressse = '';
            var postcodese = '';
            var relationship = '';
            var tpFromDate = '';
            var tpToDate = '';
            var selectedHospital = '';
            var selectedHospitalNodeId = '';
            var entirePatientRecord = '';
            var pathologyRecords = '';
            var radiologyRecords = '';
            var pharmacyRecords = '';
            var outpatientRecords = '';
            var physiotherapyRecords = '';
            var theatreRecords = '';
            var wardRecords = '';
            var oncologyRecords = '';
            var endoscopyRecords = '';
            var other = '';
            var emailCorrespondence = '';
            var anyEmails = '';
            var anyNamesKeywords = '';
            var complaints = '';
            var billingInfo = '';
            var telRecordings = '';
            var otherSpecific = '';
            var infoCorrect = '';
            var declarationFullName = '';
            var declarationDate = '';

            // Handle Radio button choices.
            var _personalDataRequest = $("input[type='radio'][name='personalDataRequest']:checked");
            if (typeof _personalDataRequest != 'undefined') {
                if (typeof $(_personalDataRequest).val() != 'undefined') {
                    personalDataRequest = $(_personalDataRequest).val();
                }
            }

            var _gender = $("input[type='radio'][name='gender']:checked");
            if (typeof _gender != 'undefined') {
                if (typeof $(_gender).val() != 'undefined') {
                    gender = $(_gender).val();
                }
            }

            var _genderse = $("input[type='radio'][name='genderse']:checked");
            if (typeof _genderse != 'undefined') {
                if (typeof $(_genderse).val() != 'undefined') {
                    genderse = $(_genderse).val();
                }
            }

            var _proofAuth = $("input[type='radio'][name='proofAuth']:checked");
            if (typeof _proofAuth != 'undefined') {
                if (typeof $(_proofAuth).val() != 'undefined') {
                    proofAuth = $(_proofAuth).val();
                }
            }

            var _entirePatientRecord = $("input[type='radio'][name='patientRecord']:checked");
            if (typeof _entirePatientRecord != 'undefined') {
                if (typeof $(_entirePatientRecord).val() != 'undefined') {
                    entirePatientRecord = $(_entirePatientRecord).val();
                }
            }

            var _emailCorrespondence = $("input[type='radio'][name='emailCorrespondence']:checked");
            if (typeof _emailCorrespondence != 'undefined') {
                if (typeof $(_emailCorrespondence).val() != 'undefined') {
                    emailCorrespondence = $(_emailCorrespondence).val();
                }
            }

            var _complaints = $("input[type='radio'][name='complaints']:checked");
            if (typeof _complaints != 'undefined') {
                if (typeof $(_complaints).val() != 'undefined') {
                    complaints = $(_complaints).val();
                }
            }

            var _billingInfo = $("input[type='radio'][name='billingInfo']:checked");
            if (typeof _billingInfo != 'undefined') {
                if (typeof $(_billingInfo).val() != 'undefined') {
                    billingInfo = $(_billingInfo).val();
                }
            }
           
            var _telRecordings = $("input[type='radio'][name='telRecordings']:checked");
            if (typeof _telRecordings != 'undefined') {
                if (typeof $(_telRecordings).val() != 'undefined') {
                    telRecordings = $(_telRecordings).val();
                }
            }

            var _infoCorrect = $("input[type='radio'][name='infoCorrect']:checked");
            if (typeof _infoCorrect != 'undefined') {
                if (typeof $(_infoCorrect).val() != 'undefined') {
                    infoCorrect = $(_infoCorrect).val();
                }
            }

            // Patient Records
            var _rb1 = $("input[type='radio'][name='rb1']:checked");
            if (typeof _rb1 != 'undefined') {
                if (typeof $(_rb1).val() != 'undefined') {
                    pathologyRecords = $(_rb1).val();
                }
            }
            var _rb2 = $("input[type='radio'][name='rb2']:checked");
            if (typeof _rb2 != 'undefined') {
                if (typeof $(_rb2).val() != 'undefined') {
                    radiologyRecords = $(_rb2).val();
                }
            }
            var _rb3 = $("input[type='radio'][name='rb3']:checked");
            if (typeof _rb3 != 'undefined') {
                if (typeof $(_rb3).val() != 'undefined') {
                    pharmacyRecords = $(_rb3).val();
                }
            }
            var _rb4 = $("input[type='radio'][name='rb4']:checked");
            if (typeof _rb4 != 'undefined') {
                if (typeof $(_rb4).val() != 'undefined') {
                    outpatientRecords = $(_rb4).val();
                }
            }
            var _rb5 = $("input[type='radio'][name='rb5']:checked");
            if (typeof _rb5 != 'undefined') {
                if (typeof $(_rb5).val() != 'undefined') {
                    physiotherapyRecords = $(_rb5).val();
                }
            }
            var _rb6 = $("input[type='radio'][name='rb6']:checked");
            if (typeof _rb6 != 'undefined') {
                if (typeof $(_rb6).val() != 'undefined') {
                    theatreRecords = $(_rb6).val();
                }
            }
            var _rb7 = $("input[type='radio'][name='rb7']:checked");
            if (typeof _rb7 != 'undefined') {
                if (typeof $(_rb7).val() != 'undefined') {
                    wardRecords = $(_rb7).val();
                }
            }
            var _rb8 = $("input[type='radio'][name='rb8']:checked");
            if (typeof _rb8 != 'undefined') {
                if (typeof $(_rb8).val() != 'undefined') {
                    oncologyRecords = $(_rb8).val();
                }
            }
            var _rb9 = $("input[type='radio'][name='rb9']:checked");
            if (typeof _rb9 != 'undefined') {
                if (typeof $(_rb9).val() != 'undefined') {
                    endoscopyRecords = $(_rb9).val();
                }
            }


            // Handle Dates
            if (typeof $("#DOB-day") != 'undefined') {
                if (typeof $("#DOB-day").val() != 'undefined') {
                    var parsed = parseInt($("#DOB-day").val(), 10);
                    if (!isNaN(parsed)) {
                        dobDay = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#DOB-month") != 'undefined') {
                if (typeof $("#DOB-month").val() != 'undefined') {
                    var parsed = parseInt($("#DOB-month").val(), 10);
                    if (!isNaN(parsed)) {
                        dobMonth = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#DOB-year") != 'undefined') {
                if (typeof $("#DOB-year").val() != 'undefined') {
                    var parsed = parseInt($("#DOB-year").val(), 10);
                    if (!isNaN(parsed)) {
                        dobYear = parsed;
                    }
                    parsed = undefined;
                }
            }
            dob = (dobDay && dobMonth && dobYear) ? dobYear + "/" + dobMonth + "/" + dobDay : 'Date of birth not set';

            if (typeof $("#DOB-day-se") != 'undefined') {
                if (typeof $("#DOB-day-se").val() != 'undefined') {
                    var parsed = parseInt($("#DOB-day-se").val(), 10);
                    if (!isNaN(parsed)) {
                        dobDayse = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#DOB-month-se") != 'undefined') {
                if (typeof $("#DOB-month-se").val() != 'undefined') {
                    var parsed = parseInt($("#DOB-month-se").val(), 10);
                    if (!isNaN(parsed)) {
                        dobMonthse = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#DOB-year-se") != 'undefined') {
                if (typeof $("#DOB-year-se").val() != 'undefined') {
                    var parsed = parseInt($("#DOB-year-se").val(), 10);
                    if (!isNaN(parsed)) {
                        dobYearse = parsed;
                    }
                    parsed = undefined;
                }
            }
            dobse = (dobDayse && dobMonthse && dobYearse) ? dobYearse + "/" + dobMonthse + "/" + dobDayse : 'Date of birth not set';


            if (typeof $("#tp-from-day") != 'undefined') {
                if (typeof $("#tp-from-day").val() != 'undefined') {
                    var parsed = parseInt($("#tp-from-day").val(), 10);
                    if (!isNaN(parsed)) {
                        tpFromDay = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#tp-from-month") != 'undefined') {
                if (typeof $("#tp-from-month").val() != 'undefined') {
                    var parsed = parseInt($("#tp-from-month").val(), 10);
                    if (!isNaN(parsed)) {
                        tpFromMonth = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#tp-from-year") != 'undefined') {
                if (typeof $("#tp-from-year").val() != 'undefined') {
                    var parsed = parseInt($("#tp-from-year").val(), 10);
                    if (!isNaN(parsed)) {
                        tpFromYear = parsed;
                    }
                    parsed = undefined;
                }
            }
            tpFromDate = (tpFromDay && tpFromMonth && tpFromYear) ? tpFromDay + "/" + tpFromMonth + "/" + tpFromYear : 'From time period not set';

            if (typeof $("#tp-to-day") != 'undefined') {
                if (typeof $("#tp-to-day").val() != 'undefined') {
                    var parsed = parseInt($("#tp-to-day").val(), 10);
                    if (!isNaN(parsed)) {
                        tpToDay = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#tp-to-month") != 'undefined') {
                if (typeof $("#tp-to-month").val() != 'undefined') {
                    var parsed = parseInt($("#tp-to-month").val(), 10);
                    if (!isNaN(parsed)) {
                        tpToMonth = parsed;
                    }
                    parsed = undefined;
                }
            }
            if (typeof $("#tp-to-year") != 'undefined') {
                if (typeof $("#tp-to-year").val() != 'undefined') {
                    var parsed = parseInt($("#tp-to-year").val(), 10);
                    if (!isNaN(parsed)) {
                        tpToYear = parsed;
                    }
                    parsed = undefined;
                }
            }
            tpToDate = (tpToDay && tpToMonth && tpToYear) ? tpToDay + "/" + tpToMonth + "/" + tpToYear : 'To time period not set';


            // Handle Dropdowns
            title = $("#title").val();
            titlese = $("#title-se").val();
            selectedHospital = $("#hospitals option:selected").text();
            selectedHospitalNodeId = $("#hospitals").val();
            relationship = $("#relationship").val();

            // Handle textboxes
            
            title = $("#title").val();
            surname = $("#surname").val();
            firstname = $("#first-name").val();
            maidenname = $("#maiden-name").val();
            emailAddress = $("#enquiry-email").val();
            phoneNumber = $("#phone-number").val();
            address = $("#address").val();
            postcode = $("#postcode").val();
            titlese = $("#title-se").val();
            surnamese = $("#surname-se").val();
            firstnamese = $("#first-name-se").val();
            maidennamese = $("#maiden-name-se").val();
            emailAddressse = $("#enquiry-email-se").val();
            phoneNumberse = $("#phone-number-se").val();
            addressse = $("#address-se").val();
            postcodese = $("#postcode-se").val();
            otherPatientRecord = $("#other-patient-record").val();
            otherSpecific = $("#other-specific").val();
            declarationFullName = $("#declaration-full-name").text();
            declarationDate = $("#declaration-date").text();
            anyEmails = $("#any-emails").val();
            anyNamesKeywords = $("#any-names-keywords").val();


            // prepare the object before posting.
            var requestFields = {
                "personalDataRequest": personalDataRequest,
                "title": title,
                "surname": surname,
                "firstname": firstname,
                "maidenname": maidenname,
                "gender": gender,
                "dob": dob,
                "emailAddress": emailAddress,
                "phoneNumber": phoneNumber,
                "address": address,
                "postcode": postcode,
                "titlese": titlese,
                "surnamese": surnamese,
                "firstnamese": firstnamese,
                "maidennamese": maidennamese,
                "genderse": genderse,
                "dobse": dobse,
                "proofAuth": proofAuth,
                "emailAddressse": emailAddressse,
                "phoneNumberse": phoneNumberse,
                "addressse": addressse,
                "postcodese": postcodese,
                "relationship": relationship,
                "tpFromDate": tpFromDate,
                "tpToDate": tpToDate,
                "hospital": selectedHospital,
                "hospitalNodeId": selectedHospitalNodeId,
                "entirePatientRecord": entirePatientRecord,
                "pathologyRecords": pathologyRecords,
                "radiologyRecords": radiologyRecords,
                "pharmacyRecords": pharmacyRecords,
                "outpatientRecords": outpatientRecords,
                "physiotherapyRecords": physiotherapyRecords,
                "theatreRecords": theatreRecords,
                "wardRecords": wardRecords,
                "oncologyRecords": oncologyRecords,
                "endoscopyRecords": endoscopyRecords,
                "otherPatientRecord": otherPatientRecord,
                "emailCorrespondence": emailCorrespondence,
                "anyEmails": anyEmails,
                "anyNamesKeywords": anyNamesKeywords,
                "complaints": complaints,
                "billingInfo": billingInfo,
                "telRecordings": telRecordings,
                "otherSpecific": otherSpecific,
                "infoCorrect": infoCorrect,
                "declarationFullName": declarationFullName,
                "declarationDate": declarationDate
            };

            $submitButton.prop('disabled', true);
            $spinner.addClass('request-booking-form__button-container__spinner--on');  

            postRequestForm(requestFields, thisForm, $submitButton, $spinner);
        }
        else {
            // send a GA tracking to say the form wasn't validated.'
        }
    }
}

var eventBookingForm = {
    getParsleyConfig: function () {
        return {
            errorsContainer: function (pEle) {
                var $err = pEle.$element.parents('.js-enquiry-form__tabs__content__inner__container__element').find('.error');
                return $err;
            },
            classHandler: function (el) {
                return el.$element.closest('.js-enquiry-form__tabs__content__inner__container__element');
            },
            uiEnabled: true
        };
    },
    init: function () {
        var that = this;
        var isBookingForm = $(".js-event-booking-form").length > 0;
        if (!isBookingForm) {
            return;
        }
        $(".js-event-booking-form").parsley(that.getParsleyConfig()).on('field:validated', function () { });
        $(".js-event-booking-form__submit").on('click', function (e) {
            e.preventDefault();
            var thisForm = $(this).parents(".js-event-booking-form");
            that.submitForm(thisForm);

            setTimeout(function () {
                if ($(".js-event-booking-form").find('#parsley-id-17').children('li').length > 0) {
                    gaHelper.trackEvent("Wrong email entered", "Submit", 'Not a valid email', undefined, true);
                }
                else if ($(".js-event-booking-form").find('#parsley-id-19').children('li').length > 0) {
                    gaHelper.trackEvent("Wrong email entered", "Submit", 'Not a valid email', undefined, true);
                }

                if ($(".js-event-booking-form").find('#parsley-id-21').children('li').length > 0) {
                    gaHelper.trackEvent("Wrong phone number entered", "Submit", 'Not a valid phonenumber', undefined, true);
                }
                

            }, 2000);
        });
    },

    removeLastComma: function (str) {
        return str.replace(/,\s*$/, "")
    },

    submitForm: function (thisForm) {
        var that = this;
        var $submitButton = $(thisForm).find(".js-event-booking-form__submit");
        var $spinner = thisForm.find('.event-booking-form__button-container__spinner');

        var form = $(thisForm).parsley(that.getParsleyConfig());
        if (form.validate()) {

            var age = '';
            var firstName = thisForm.find('#first-name').val();
            var surname = thisForm.find('#surname').val();
            var dobDay = thisForm.find(".js-dob-day").val() ? parseInt(thisForm.find(".js-dob-day").val(), 10) : false;
            var dobMonth = thisForm.find(".js-dob-month").val() ? parseInt(thisForm.find(".js-dob-month").val(), 10) : false;
            var dobYear = thisForm.find(".js-dob-year").val() ? parseInt(thisForm.find(".js-dob-year").val(), 10) : false;
            var dob = (dobDay && dobMonth && dobYear) ? dobYear + "/" + dobMonth + "/" + dobDay : 'Date of birth not set';
            var emailAddress = thisForm.find('#event-booking-email').val(); // CHECK NAME
            var emailAddressConfirm = thisForm.find('#event-booking-email-confirm').val();
            var phoneNum = thisForm.find('#phone-number').val();            
            var eventDateValue = thisForm.find('#eventDate').val();-form
            var eventTitle = thisForm.find('#eventTitle').val();            
            var nodeId = thisForm.find('#hidden-node-id').text();
            var nearestHospitalName = ''; 
            var nearestHospitalId = thisForm.find('#nearestHospitalId').val();
            var bookingFormDestinationEmail = thisForm.find("#bookingFormDestinationEmail").val();
            var bookingFormSpecificTopicsCovered = thisForm.find("#enquiry").val();
            var howDidYouHearAboutUs = thisForm.find("#enquiry-source").val();            
            var enquiryFormOptinEmail = thisForm.find("#optinEmail").prop("checked");
            var enquiryFormOptinSMS = thisForm.find("#optinSms").prop("checked");
            var enquiryFormOptinPrivacy = thisForm.find("#optinPrivacy").prop("checked");
            //contact bast time  //thisForm.find('#enquiry-besttime-to-contact').text();
            var enquiryBestTimeToContact = thisForm.find('#enquiry-besttime-to-contact').val();   

            try {
                if (dob !== 'Date of birth not set') {
                    age = ~~((Date.now() - +new Date(dob)) / (31557600000));
                } else {
                    age = 'Date of birth not set';
                }

            } catch (e) {
            }

            var marketingOptin = "";
            if (enquiryFormOptinEmail) {
                marketingOptin += "email,"
            }
            if (enquiryFormOptinSMS) {
                marketingOptin += "sms,"
            }

            var enquiryFields = {
                "firstName": firstName,
                "surname": surname,
                "dob": dob,
                "emailAddress": emailAddress,
                "emailAddressConfirm": emailAddressConfirm,
                "phoneNum": phoneNum,
              //  "enquiryFormOptin": enquiryFormOptin,
                "isEvent": true,
                "eventDate": eventDateValue,
                "eventTitle": eventTitle,
                "nearestHospitalId": nearestHospitalId,
                "nodeId": nodeId,
                "bookingFormDestinationEmail": bookingFormDestinationEmail,
               // "enquiryFormOptin": enquiryFormOptin,
                "bookingFormSpecificTopicsCovered": bookingFormSpecificTopicsCovered,
                "howDidYouHearAboutUs": howDidYouHearAboutUs,
                "enquiryFormOptinEmail": enquiryFormOptinEmail,
                "enquiryFormOptinSMS": enquiryFormOptinSMS,
                "enquiryFormOptinPrivacy": enquiryFormOptinPrivacy,
                "enquiryBestTimeToContact": enquiryBestTimeToContact,
                "marketingOptIn": this.removeLastComma(marketingOptin)
            };
                      
            $submitButton.prop('disabled', true);
            $spinner.addClass('event-booking-form__button-container__spinner--on');  

            postEventForm(enquiryFields, thisForm, age, $submitButton, $spinner);
            
        }
        else {
            // send a GA tracking to say the form wasn't validated.'
        }
    },


};

var enquiryForm = {

    recordCustomErrorDataLayer: function(field, message) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'enquire_submission',
            'error_field': field,
            'error_message': message
        });
    },
    bindErrorRecorder: function () {
        var that = this;

        window.Parsley.on('field:error', function () {
            const field = this.$element[0];
            const id = field.id ? field.id : field.name;
            const required = this.validationResult[0].assert.name == 'required';
            var requiredMsg = this.domOptions.requiredMessage ? this.domOptions.requiredMessage : "Value required.";
            const msg = required ? requiredMsg  : this.domOptions.patternMessage;

            that.recordCustomErrorDataLayer(id, msg);
        });
    },    
    getParsleyConfig: function () {
        return {
            errorsContainer: function (pEle) {
                var $err = pEle.$element.parents('.js-enquiry-form__tabs__content__inner__container__element').find('.error');
                return $err;
            },
            classHandler: function (el) {
                return el.$element.closest('.js-enquiry-form__tabs__content__inner__container__element');
            },
            uiEnabled: true
        };
    },
    customValidator: function () {

        var $dobErrorRequiredContainer = $('.js-date-required-concatenate'),
            $dobContainer = $('.enquiry-form__tabs__content__inner__container__element--dob--required');

        $('.js-enquiry-form__submit').on("click", function(){

            var thisForm = $(this).parents(".js-enquiry-form");

            $dobErrorRequiredContainer = $(thisForm).find('.js-date-required-concatenate');
            $dobContainer = $(thisForm).find('.enquiry-form__tabs__content__inner__container__element--dob--required');

            var day = parseInt($(thisForm).find(".js-dob-day-required").val(),10);
            var month = parseInt($(thisForm).find(".js-dob-month-required").val(),10);
            var year = parseInt($(thisForm).find(".js-dob-year-required").val(),10);

            if(isNaN(day) || isNaN(month) || isNaN(year)){

                $dobErrorRequiredContainer.text('Please complete this information.').show();
                $dobContainer.addClass('enquiry-form__tabs__content__inner__container__element--error');

                
            }  else {
                $dobErrorRequiredContainer.hide();
                $dobContainer.removeClass('enquiry-form__tabs__content__inner__container__element--error');

            }

        });

        var $form = $(".js-enquiry-form");

        $form.find(".js-dob-day, .js-dob-month, .js-dob-year").on("change", function(){

            var thisForm = $(this).parents(".js-enquiry-form");

            $dobErrorRequiredContainer = $(thisForm).find('.js-date-required-concatenate');
            $dobContainer = $(thisForm).find('.enquiry-form__tabs__content__inner__container__element--dob--required');

            var day = parseInt($(thisForm).find(".js-dob-day").val(),10);
            var month = parseInt($(thisForm).find(".js-dob-month").val(),10);
            var year = parseInt($(thisForm).find(".js-dob-year").val(),10);

            if(!isNaN(day) && !isNaN(month) && !isNaN(year)){
                $(thisForm).find(".js-dob-concatenate").val(day+"/"+month+"/"+year);
            }

            if(isNaN(day) || isNaN(month) || isNaN(year)){

                $dobErrorRequiredContainer.text('Please complete this information.').show();
                $dobContainer.addClass('enquiry-form__tabs__content__inner__container__element--error');

                
            }  else {
                $dobErrorRequiredContainer.hide();
                $dobContainer.removeClass('enquiry-form__tabs__content__inner__container__element--error');

            }
        });

        // new appointment for verify eror on select field
        $form.find(".js-dob-day-required, .js-dob-month-required, .js-dob-year-required").on("change", function(){

            var thisForm = $(this).parents(".js-enquiry-form");

            $dobErrorRequiredContainer = $(thisForm).find('.js-date-required-concatenate');
            $dobContainer = $(thisForm).find('.enquiry-form__tabs__content__inner__container__element--dob--required');

            var day = parseInt($(thisForm).find(".js-dob-day-required").val(),10);
            var month = parseInt($(thisForm).find(".js-dob-month-required").val(),10);
            var year = parseInt($(thisForm).find(".js-dob-year-required").val(),10);

            if(isNaN(day) || isNaN(month) || isNaN(year)){

                $dobErrorRequiredContainer.text('Please complete this information.').show();
                $dobContainer.addClass('enquiry-form__tabs__content__inner__container__element--error');

                
            }  else {
                $dobErrorRequiredContainer.hide();
                $dobContainer.removeClass('enquiry-form__tabs__content__inner__container__element--error');

            }


        });

        window.Parsley.addValidator('date', {
            requirementType: 'string',
            validateString: function(value, requirements, parsleyInstance) {

                var $row = $(parsleyInstance.$element).parents(".js-enquiry-form__tabs__content__inner__container__element");

                var day = parseInt($row.find(".js-dob-day").val(),10);
                var month = parseInt($row.find(".js-dob-month").val(),10);
                var year = parseInt($row.find(".js-dob-year").val(),10);

                if(!isNaN(day) && !isNaN(month) && !isNaN(year)){

                     var age = helper.age(day,month,year);

                    return age > 17;
                }
            },
            messages: {
                en: 'You must be over 18 to submit an enquiry',
            }
        });         
    },
    emailValidator: function () {     

        window.Parsley.addValidator('customequalto', {
            requirementType: 'string',
            
            validateString: function(value, elem, parsleyInstance) {

                $(elem).on("keyup", _.debounce(function () {
                    parsleyInstance.validate();
                }, 100));

                if (value.length > 0 && $( elem ).length > 0) {
                    return value.toLowerCase() === $( elem ).val().toLowerCase();
                }
                return false;
            }
        });
    },

    removeLastComma: function (str) {
        return str.replace(/,\s*$/, "")
    },

    submitForm: function (thisForm) {

        var that = this;
        var form = $(thisForm).parsley(that.getParsleyConfig());

        var response = grecaptcha.getResponse();
        if (response.length == 0) {
            $("#recaptchaError").toggle();
        }
        else {
            $("#recaptchaError").hide();

            var $submitButton = $(thisForm).find(".js-enquiry-form__submit");

            that.bindErrorRecorder();
            var formValid = form.validate();
            
            if (formValid) {

                $submitButton.prop('disabled', true);
                var $spinner = thisForm.find('.enquiry-form__button-container__spinner');
                $spinner.addClass('enquiry-form__button-container__spinner--on');  

                //todo: prefix $ objects with $
                var enquirySource = thisForm.find('#hidden-enquiry-source').text();
                var enquiryType = thisForm.find('#enquiry-type option:selected').text();
                var fundingType = thisForm.find('#funding-type option:selected').text();
                var enquiry = thisForm.find('#enquiry').val();
                var selectedHospital = thisForm.find('#select-hospital');
                var selectedHospitalValue = selectedHospital.val();
            
                var title = thisForm.find('#title').val();
                var firstName = thisForm.find('#first-name').val();
                var surname = thisForm.find('#surname').val();
                var dobDay = thisForm.find(".js-dob-day").val() ? parseInt(thisForm.find(".js-dob-day").val(), 10) : false;
                var dobMonth = thisForm.find(".js-dob-month").val() ? parseInt(thisForm.find(".js-dob-month").val(), 10) : false;
                var dobYear = thisForm.find(".js-dob-year").val() ? parseInt(thisForm.find(".js-dob-year").val(), 10) : false;
                var dob = (dobDay && dobMonth && dobYear) ? dobYear + "/" + dobMonth + "/" + dobDay : 'Date of birth not set';
                var postcodeRaw = thisForm.find('#postcode').val();		    
                var postcode = postcodeRaw;
                var emailAddress = thisForm.find('#enquiry-email').val(); // CHECK NAME
                var emailAddressConfirm = thisForm.find('#enquiry-email-confirm').val();
                var phoneNum = thisForm.find('#phone-number').val();
                var enquiryFormOptin = thisForm.find('.enquiry-form__tabs__content__inner__container__element__checkbox').is(':checked');
                var nodeId = thisForm.find('#hidden-node-id').text();
                var isEnquiry = thisForm.find('#hidden-is-enquiry').text();
                var thankYouHeader = $(thisForm).data('thanks-header');
                var thankYouText = $(thisForm).data('thanks-text');
                var nearestHospitalName = '';
                var nearestHospitalId = '';
                var timerId = 'enquiry-form-thankyou-timer';
                var timer = thisForm.find('#' + timerId);
                var age = '';
                var partialPostCode = postcode; 
                var floodLightCategory = 'mkenqry';
                var floodLightEnquiryString = 'u7=' + enquiryType;
                var hospitalNotFoundMessage = 'Your nearest hospital could not be found with the postcode provided. Please check your postcode or select a hospital manually.';

              /*  var optinSMS = thisForm.find('.enquiry-form__tabs__content__inner__container__element__checkbox__sms').is(':checked');
                var optinEmail = thisForm.find('.enquiry-form__tabs__content__inner__container__element__checkbox__email').is(':checked');
                var optinPrivacy = thisForm.find('.enquiry-form__tabs__content__inner__container__element__checkbox__privacy').is(':checked');
            */

                var optinEmail = thisForm.find("input[name='optinEmail']").prop("checked");
                var optinSMS = thisForm.find("input[name='optinSms']").prop("checked");
                var optinPrivacy = thisForm.find("input[name='optinPrivacy']").prop("checked");

                //Apointment-Fields 
                var preferredConsultant = thisForm.find('#preferred-consultant').val();
                var selectedSpeciality = thisForm.find('#select-speciality');
                var selectedSpecialityValue = selectedSpeciality.val();
                var preferredDate = thisForm.find('#date').val();
                var selectedTime = thisForm.find('#select-time');
                var selectedTimeValue = selectedTime.val();
                var isAppointment = thisForm.find('#hidden-is-appointment').text();

                //
                //contact bast time
                var enquiryBestTimeToContact = thisForm.find('#enquiry-besttime-to-contact option:selected').text();
            


                try {
                    if (dob !== 'Date of birth not set') {
                        age = ~~((Date.now() - +new Date(dob)) / (31557600000));
                    } else {
                        age = 'Date of birth not set';
                    }
                			
                } catch (e) {
                }

                try {
                    var match = postcodeRaw.match(/(([A-Za-z]{1,2}[0-9]{1,2})(|[ 0-9]))(?=[0-9][A-Za-z])/); //Get first part of UK postcode
                    partialPostCode = $.trim(match[0]);
                    if (!partialPostCode) partialPostCode = postcode;	
                } catch (e) {
                }

                if (isEnquiry === 'False') {
                    emailAddress = thisForm.find('#callback-email').val();
                    emailAddressConfirm = thisForm.find('#callback-email-confirm').val();
                    timerId = 'callback-form-thankyou-timer';
                    floodLightCategory = 'rqtcllbk';
                    floodLightEnquiryString = 'u8=' + enquiryType;
                }  

                try {
                    floodLightEnquiryString = encodeURI(floodLightEnquiryString);
                } catch (e) {
                }

                var marketingOptin = "";
                if (optinEmail) {
                    marketingOptin += "email,"
                }
                if (optinSMS) {
                    marketingOptin += "sms,"
                }

                var enquiryFields = {
                    "enquirySource": enquirySource,
                    "enquiryType": enquiryType,
                    "fundingType": fundingType,
                    "enquiry": enquiry,
                    "title": title,
                    "firstName": firstName,
                    "surname": surname,
                    "dob": dob,
                    "postcode": postcode,
                    "emailAddress": emailAddress,
                    "emailAddressConfirm": emailAddressConfirm,
                    "phoneNum": phoneNum,
                    "enquiryFormOptin": enquiryFormOptin,
                    "nodeId": nodeId,
                    "isEnquiry": isEnquiry,
                    "nearestHospitalName": nearestHospitalName,
                    "nearestHospitalId": nearestHospitalId,
                    "perferredConsultant": preferredConsultant,
                    "perferredSpeciality": selectedSpecialityValue,
                    "perferredDate": preferredDate,
                    "perferredTime": selectedTimeValue,
                    "isAppointment": isAppointment,
                    "isEvent": false,
                    "enquiryFormOptinSMS": optinSMS,
                    "enquiryFormOptinEmail": optinEmail,
                    "enquiryFormOptinPrivacy": optinPrivacy,
                    "enquiryBestTimeToContact": enquiryBestTimeToContact,
                    "marketingOptIn": this.removeLastComma(marketingOptin)
                };

                if (selectedHospitalValue === 'nearest') { // Get nearest hospital

                    $.ajax({
                        type: "POST",
                        url: apilocation + 'EnquiryFormApi/NearestHospital',
                        data: JSON.stringify({ "postcode": postcode }),
                        contentType: "application/json; charset=utf-8",
                        dataType: "json"
                    }).done(function (data) {

                        if (data.length > 0) {
                            enquiryFields.nearestHospitalName = data[0];
                            enquiryFields.nearestHospitalId = data[1];
                            postForm(enquiryFields, thisForm, age, $submitButton, floodLightCategory, partialPostCode, floodLightEnquiryString, $spinner);

                        } else { // Error: Hospital not found

                            $submitButton.prop('disabled', false);
                            $spinner.removeClass('enquiry-form__button-container__spinner--on'); 

                            $postcodeDiv = $('.enquiry-form__tabs__content__inner__container__element--postcode');
                            $selectHospitalDiv = $('.js-enquiry-form__tabs__content__inner__container__element-select-hospital');
                            gaHelper.trackEvent("Nearest hospital not found", "Submit", undefined, undefined, true);

                            if ($postcodeDiv.length > 0) {
                                $postcodeDiv.addClass('enquiry-form__tabs__content__inner__container__element--error');                            
                            }

                            if ($selectHospitalDiv.length > 0) {
                                $selectHospitalDiv.addClass('enquiry-form__tabs__content__inner__container__element--error');
                                $selectHospitalDiv.find('.error').html(hospitalNotFoundMessage);                           
                            }
                        }

                    }).always(function () {
                    });

                } else { //Hospital manually selected by user

                    enquiryFields.nearestHospitalName = $(selectedHospital).find('option:selected').text();
                    enquiryFields.nearestHospitalId = selectedHospitalValue;
                    postForm(enquiryFields, thisForm, age, $submitButton, floodLightCategory, partialPostCode, floodLightEnquiryString, $spinner);
                }                 


               
            }
        }

    },
    enquiryMobileCTAOverCtrl: function () {
        var el1 = $(".js-call-to-action__clickToCall");
        var el2 = $(".js-inpageEnquiryForm");

        if (el1.length > 0 && el2.length > 0) {
            $(window).on('scroll', function () {
                if ($(".js-inpageEnquiryForm").visible(true)) {
                    $(".js-call-to-action__clickToCall").addClass("is-hidden");
                }
                else {
                    $(".js-call-to-action__clickToCall").removeClass("is-hidden");
                }
            });
        }
    },
    init: function () {
        var isEnquiryForm = $("#enquiry-form").length > 0;
        if (!isEnquiryForm) {
            return;
        }

        var that = this;
        that.enquiryMobileCTAOverCtrl();
        that.customValidator();
        that.emailValidator();

        $(".js-enquiry-form__submit").on('click', function (e) {
            e.preventDefault();
            var thisForm = $(this).parents(".js-enquiry-form");
            that.submitForm(thisForm);

            setTimeout(function () {
                //if ($(".js-enquiry-form").find('#parsley-id-31').children('li').length > 0) {
                //    gaHelper.trackEvent("Wrong postcode", "Submit", 'Not a valid uk postcode', undefined, true);
                //}
                //else if ($(".js-enquiry-form").find('#parsley-id-62').children('li').length > 0) {
                //    gaHelper.trackEvent("Wrong postcode", "Submit", 'Not a valid uk postcode', undefined, true);
                //}

                //if ($(".js-enquiry-form").find('#parsley-id-25').children('li').length > 0) {
                //    gaHelper.trackEvent("Wrong email entered", "Submit", 'Not a valid email', undefined, true);
                //}
                //else if ($(".js-enquiry-form").find('#parsley-id-58').children('li').length > 0) {
                //    gaHelper.trackEvent("Wrong email entered", "Submit", 'Not a valid email', undefined, true);
                //}


                //if ($(".js-enquiry-form").find('#parsley-id-29').children('li').length > 0) {
                //    gaHelper.trackEvent("Wrong phone number entered", "Submit", 'Not a valid phonenumber', undefined, true);
                //}
                //else if ($(".js-enquiry-form").find('#parsley-id-56').children('li').length > 0) {
                //    gaHelper.trackEvent("Wrong phone number entered", "Submit", 'Not a valid phonenumber', undefined, true);
                //}

            }, 2000);
        });

        $(".js-enquiry-form").each(function (index) {

            if ($(this).parsley !== null) {
                $(this).parsley(that.getParsleyConfig()).on('field:validated', function () { });
            }

        });

    }

};

var postRequestForm = function (requestFields, thisForm, $submitButton, $spinner) {

    $.ajax({
        type: "POST",
        url: apilocation + 'EnquiryFormApi/SendSubjectAccessRequest',
        data: JSON.stringify(requestFields),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
    }).done(function (data) {

        var floodLightCategory = 'mkrequest';
        var enquiryFields = {};
        enquiryFields.enquiryType = '';
        enquiryFields.fundingType = '';
        enquiryFields.marketingOptIn = '';
        enquiryFields.nearestHospitalName = '';

        postToThankYou(false, requestFields.hospitalNodeId, requestFields.dob, floodLightCategory, requestFields.postcode, "", "", enquiryFields);
    });

};

var postEventForm = function (enquiryFields, thisForm, age, $submitButton, $spinner) {
    // Show the spinner;
    $.ajax({
        type: "POST",
        url: apilocation + 'EnquiryFormApi/SendEventEnquiry',
        data: JSON.stringify(enquiryFields),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
    }).done(function (data) {
        //if (data.length > 0 && (data.length != 2 && data[0] != "WebId")) {
        if (data.length > 0 && data[0] != "WebId") {
            var errorString = "";
            try {
                errorString = JSON.stringify(data).replace('{', '').replace('}', '');
            } catch (e) {
            }

            if (enquiryFields.isEvent === 'True') {
                gaHelper.trackEvent("Event Booking", "Server Side Error", errorString, undefined, true);
            }
        }
        else {
            $(".event-booking-form__content").removeClass("is-on");
            $(".thank-you-event-booking-form__content").addClass("is-on");

            setTimeout(function () {
                $('#event-booking-form')[0].reset();
                $(".thank-you-event-booking-form__content").removeClass("is-on");
                $(".event-booking-form__content").addClass("is-on");
            }, 4000);

            gaHelper.trackEvent("Event Booking", "Successful Submit", "Current URL: " + window.location.href + ", Enquiry type: Event" + ", Age: " + age + ", Marketing opt in: " + enquiryFields.enquiryFormOptin, undefined, false);
        }

        $submitButton.prop('disabled', false);
        $spinner.removeClass('event-booking-form__button-container__spinner--on');

        }).fail(function (data) {

            gaHelper.trackEvent("Event Booking", "Failed to Submit", "Current URL: " + window.location.href + ", Enquiry type: Event" + ", Age: " + age + ", Marketing opt in: " + enquiryFields.enquiryFormOptin, undefined, false);

            $submitButton.prop('disabled', false);
            $spinner.removeClass('event-booking-form__button-container__spinner--on');
        });
};

var recaptchaExpired = function () {
    grecaptcha.reset();
    var $submitButton = $(thisForm).find(".js-enquiry-form__submit");
    $submitButton.prop('disabled', false);
}

var postForm = function (enquiryFields, thisForm, age, $submitButton, floodLightCategory, partialPostCode, floodLightEnquiryString, $spinner) {    

    $.ajax({
        type: "POST",
        url: apilocation + 'EnquiryFormApi/SendEnquiry',
        data: JSON.stringify(enquiryFields),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
    }).done(function (data) {

        var isInPageEnquiry = $("#in-page-enquiry").val();

        $("#inpageEnquiryForm").hide();
        $("#inpageEnquiryThankyou").show();

        var hospital = enquiryFields.nearestHospitalName;

        //ga('send', 'event', { 'dimension5': hospital });

        // Inpage Enquiry Form
        if (data.length > 0 && (data.length != 2 && data[0] != "WebId")) {
            var errorString = "";
            try {
               errorString = JSON.stringify(data2).replace('{', '').replace('}', '');
            } catch(e) {                
            }

            if (enquiryFields.isEnquiry === 'True') {
                gaHelper.trackEvent("Enquiry", "Server Side Error", errorString, undefined, true);
            } else {
                gaHelper.trackEvent("Call back", "Server Side Error", errorString, undefined, true);
            }
            

        } else { // Main enquiry form which posts to thank you page.

            var webId = data[1];
            dataLayer = window.dataLayer || [];  

            if (isInPageEnquiry == "true") {
                //ga('send', 'event', { 'dimension4': webId });
                dataLayer.push({ 'webId': webId });
            }

            dataLayer.push({
                "event": "enquire_submission",
                "web_id": webId,
                "request_type": enquiryFields.enquiryType,
                "funding_type": enquiryFields.fundingType,
                "hospital_selected": enquiryFields.nearestHospitalName,
                "marketing_opt_in": enquiryFields.marketingOptIn
            });

            //dataLayer.push({ 'enquiryType': "'" + enquiryFields.enquiryType + "'" });
            //dataLayer.push({ 'fundingType': "'" + enquiryFields.fundingType + "'" });
            //dataLayer.push({ 'marketingOptIn': "'" + enquiryFields.marketingOptIn + "'" });

            postToThankYou(enquiryFields.isEnquiry, enquiryFields.nearestHospitalId, enquiryFields.dob, floodLightCategory, partialPostCode, floodLightEnquiryString, webId, enquiryFields);

            if (enquiryFields.isEnquiry === 'True') {

                // SW-0655 debug, does the url contain query string params ? if so we want to strip them off and add browser info to the GA event
                var browserInfo = '';
                var enquiryFormUrl = window.location.href;
                var urlHasQueryString = enquiryFormUrl.indexOf('?') > -1;

                if (urlHasQueryString) {
                    enquiryFormUrl = enquiryFormUrl.split('?')[0];
                    browserInfo = navigator.appCodeName + '|' + navigator.appName + '|' + navigator.appVersion + '|' + navigator.cookieEnabled + '|' + navigator.language + '|' + navigator.onLine + '|' + navigator.platform + '|' + navigator.userAgent;
                }

                //gaHelper.trackEvent("Enquiry", "Successful Submit", "Current URL: " + window.location.href + ", Enquiry type: " + enquiryFields.enquiryType + ", Title: " + enquiryFields.title + ", Age: " + age + ", Marketing opt in: " + enquiryFields.enquiryFormOptin + ", WebId:" + webId + ", Contact:" + enquiryFields.enquiryBestTimeToContact , undefined, false);
                //gaHelper.trackEvent("Enquiry", "Successful Submit", "Current URL: " + window.location.href + ", Enquiry type: " + enquiryFields.enquiryType + ", Title: " + enquiryFields.title + ", Age: " + age + ", Marketing opt in: " + enquiryFields.enquiryFormOptin + ", WebId:" + webId, undefined, false);
              //  gaHelper.trackEvent("Enquiry", "Successful Submit", "Current URL: " + enquiryFormUrl + ", Enquiry type: " + enquiryFields.enquiryType + ", Funding Type: " + enquiryFields.fundingType + ", Title: " + enquiryFields.title + ", Age: " + age + ", Marketing opt in: " + enquiryFields.enquiryFormOptin + ", WebId:" + webId + ",BrowserInfo:" + browserInfo, undefined, false);
                //  gaHelper.trackEvent("Enquiry", "Successful Submit", "Current URL: " + enquiryFormUrl + ", Enquiry type: " + enquiryFields.enquiryType + ", Funding Type: " + enquiryFields.fundingType + ", Marketing opt in: " + enquiryFields.enquiryFormOptin + ", WebId:" + webId, undefined, false);
            } else {
                //gaHelper.trackEvent("Call back", "Successful Submit", "Current URL: " + window.location.href + ", Enquiry type: " + enquiryFields.enquiryType + ", Title: " + enquiryFields.title + ", Age: " + age + ", Marketing opt in: " + enquiryFields.enquiryFormOptin + ", WebId:" + webId + ", Contact:" + enquiryFields.enquiryBestTimeToContact, undefined, false);
                gaHelper.trackEvent("Call back", "Successful Submit", "Current URL: " + window.location.href + ", Enquiry type: " + enquiryFields.enquiryType + ", Funding Type: " + enquiryFields.fundingType + ", Title: " + enquiryFields.title + ", Age: " + age + ", Marketing opt in: " + enquiryFields.enquiryFormOptin + ", WebId:" + webId, undefined, false);
            }


            //var axel = Math.random() + "";
            //var a = axel * 10000000000000;
            //var device = "Desktop";
            //var isMobile = window.matchMedia("only screen and (max-width: 760px)");
            //if (isMobile.matches) {
            //    device = "Mobile";
            //} 
            
            //$('body').append('<!-- Start of DoubleClick Floodlight Tag: Please do not remove--><iframe src="https://6443519.fls.doubleclick.net/activityi;src=6443519;type=cntr;cat=' + floodLightCategory + ';u2=' + device + ';u4=;u5=' + partialPostCode + ';u6=' + enquiryFields.dob + ';' + floodLightEnquiryString + ';u9=' + data[1] + ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe><!-- End of DoubleClick Floodlight Tag: Please do not remove -->');            


            var floodLightThankYouAttributes = document.getElementById('js-floodlight-thankyou-attributes');
            var device = "Desktop";
            var isMobile = window.matchMedia("only screen and (max-width: 760px)");
            if (isMobile.matches) {
                device = "Mobile";
            }
            //var hospital = encodeURIComponent(document.getElementsByTagName("body")[0].getAttribute('data-hospital-name'));

            //var hospital = hospital; // encodeURIComponent($("#select-hospital option:selected").text());
            var treatment = encodeURIComponent(document.getElementsByTagName("body")[0].getAttribute('data-treatment-name'));
            var dob = enquiryFields.dob;
            var partialPostcode = partialPostCode;
            var webId = webId;

            var iframeStr = '<!-- Start Double Click --><iframe src="https://ad.doubleclick.net/ddm/activity/src=6443519;type=cntr;cat=' + floodLightCategory + ';u1=' + hospital + ';u2=' + device + ';u4=' + treatment + ';u5=' + partialPostcode + ';' + floodLightEnquiryString + ';u9=' + webId + ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=; ord=' + webId + '?" width="1" height="1" alt="" frameborder="0" style="display:none"></iframe><!-- End Double Click -->';

           // $('body').append(iframeStr);
        }
    });
};

var postToThankYou = function (isEnquiry, nearestHospitalId, dob, floodLightCategory, partialPostCode, floodLightEnquiryString, webId, enquiryFields) {

    var thankYouPostForm = $("#thankYouPost");

    thankYouPostForm.find("#isEnquiry").val(isEnquiry);
    thankYouPostForm.find("#nearestHospitalId").val(nearestHospitalId);
    thankYouPostForm.find("#dob").val(dob);
    thankYouPostForm.find("#partialPostcode").val(partialPostCode);
    thankYouPostForm.find("#floodLightEnquiryString").val(floodLightEnquiryString);
    thankYouPostForm.find("#floodLightCategory").val(floodLightCategory);
    thankYouPostForm.find("#webId").val(webId);

    thankYouPostForm.find("#enquiryType").val(enquiryFields.enquiryType);
    thankYouPostForm.find("#fundingType").val(enquiryFields.fundingType);
    thankYouPostForm.find("#marketingOptIn").val(enquiryFields.marketingOptIn);
    thankYouPostForm.find("#nearestHospitalName").val(enquiryFields.nearestHospitalName);
    thankYouPostForm.find("#emailAddress").val(enquiryFields.emailAddress);

    thankYouPostForm.submit();
};

var siteNavMobile = {
    init: function () { 

        var $navElements = $(".js-site-nav-mobile, .js-site-nav-mobile-toggle");
        var $contactElements = $(".js-contact-mobile-toggle, .js-contact-mobile");
        var $expandableNavItems = $(".site-nav-mobile__l1 ul li").has(".site-nav-mobile__l2 ul li").children('a');
        var $searchElements = $(".js-site-search-mobile-toggle, .js-site-search-mobile");

        var backgroundImg = $(".hero-home__image--mobile").attr("style");
        if (backgroundImg === undefined) {
        }
        else {
            var url = backgroundImg.replace(/(url\(|\)|'|")/gi, '');
            url = url.trim().replace("background-image:", "");
            if (url.length > 0) {
                $(".js-site-header-mobile").addClass('remove-background');
            }
        }

        $expandableNavItems.addClass('hasItems');


        $($expandableNavItems).on("click", function () {
            $(this).toggleClass("is-on");
            //$expandableNavItems.toggleClass('is-on');
        });

        // Burger Menu Clicked.
        $(".js-site-nav-mobile-toggle").on("click", function (e) {

            e.preventDefault();		

            $searchElements.removeClass("is-on");
            $contactElements.removeClass("is-on");

            if ($navElements.hasClass("is-on")) {
                $navElements.removeClass("is-on");
                $("body").removeAttr("style");
            } else { 
                $navElements.addClass("is-on");
                $contactElements.removeClass("is-on");
                $("body").attr("style", "position:fixed");
            }
        });

        // Search Menu Clicked
        $(".js-site-search-mobile-toggle").on("click", function (e) {

            e.preventDefault();

            $contactElements.removeClass("is-on");
            $navElements.removeClass("is-on");

            if ($searchElements.hasClass("is-on")) {
                $searchElements.removeClass("is-on");
            } else {
                $searchElements.addClass("is-on");
            }
        });
        

        // Contact Phone Icon clicked.
        $(".js-contact-mobile-toggle").on("click", function (e) {

            e.preventDefault();

            $searchElements.removeClass("is-on");
            $navElements.removeClass("is-on");

            if ($contactElements.hasClass("is-on")) {
                $contactElements.removeClass("is-on"); 

                $(".js-contact-mobile-toggle").removeClass("is-on");

            } else { 
                $contactElements.addClass("is-on");
                $navElements.removeClass("is-on");

                $(".js-contact-mobile-toggle").addClass("is-on");
            }
        });

        // old mega menu
        $(".site-nav-mobile__l2").siblings("a").on("click", function (e) {
            var $item = $(this).siblings(".site-nav-mobile__l2");

            if ($item.find(".site-nav-mobile__list").children().length > 0) {
                e.preventDefault();
                if($item.hasClass("is-on")){
                    $(this).siblings(".site-nav-mobile__l2").removeClass("is-on");
                } else { 
                    $(this).siblings(".site-nav-mobile__l2").addClass("is-on");
                }
            }			
        })

        // new mega menu
        $(".site-nav-mobile__l2").siblings("a").on("click", function (e) {
            var $item = $(this).siblings(".site-nav-mobile__l2");

            if ($item.find(".mega-menu__items").children().length > 0) {
                e.preventDefault();
                if($item.hasClass("is-on")){
                    $(this).siblings(".site-nav-mobile__l2").removeClass("is-on");
                } else { 
                    $(this).siblings(".site-nav-mobile__l2").addClass("is-on");
                }
            }			
        })
    }
};

var callToAction = {

    config: {
        ctaSelector: ".js-call-to-action--desktop",
        $ctaHandle : $(".js-call-to-action__handle")
    },

    init: function () {

        var c = this.config;

        if (sessionStorage.getItem('isExistingSpireSession') == null) {
            //cookies.writeCookie('ctaClosed', 0, 10); 
            try{
               sessionStorage.setItem('isExistingSpireSession', 1);
           } catch (e) {
              // console.log(e);
          }		      
        }

         var $cta = $(this).parent(c.ctaSelector);

        //if ($("body").find("section").hasClass("enquiry-form")) {
        //    $cta.removeClass("is-on");
        //    //cookies.writeCookie('ctaClosed', 1, 10);
        //} else {
        //    $cta.addClass("is-on");
        //    //cookies.writeCookie('ctaClosed', 0, 10);
        //}		

        //callToAction.toggleVisibility();

        c.$ctaHandle.parent(c.ctaSelector).removeClass("call-to-action--desktop--initial-hide"); //Class for initial hide of element to prevent flash of content when ctaClosed cookie is set, before JS kicks in


        c.$ctaHandle.on("click", function (e) {
            e.preventDefault();
            var $cta = $(this).parent(c.ctaSelector);

            if ( $cta.hasClass("is-on") || ($("body").hasClass("enquiry-form")) ) {
                $cta.removeClass("is-on");
                //cookies.writeCookie('ctaClosed', 1, 10);
            } else {
                $cta.addClass("is-on");
                //cookies.writeCookie('ctaClosed', 0, 10);
            }
        });

        //var toggleCtaVisibility = helper.debounce(function(element) {
        //    callToAction.toggleVisibility();
        //},250);
        
        //window.addEventListener('resize', toggleCtaVisibility);
    },

    //toggleVisibility: function() {

    //    var c = this.config,
    //        $window = $(window),
    //        $cta = c.$ctaHandle.parent(c.ctaSelector);

    //    if ( 
    //        //SP-840: Stop content being obstructed at certain resolutions 
    //        //(($window.height() < 1100 && $window.width() < 1550 && $window.width() > 1150 || $window.width() < 1250) && (sessionStorage.getItem('isExistingSpireSession') == null || cookies.readCookie('ctaClosed') != 0)) 
    //        //|| cookies.readCookie('ctaClosed') && cookies.readCookie('ctaClosed') != 0) { 

    //    ($window.height() < 1100 && $window.width() < 1550 && $window.width() > 1150 || $window.width() < 1250) && (sessionStorage.getItem('isExistingSpireSession') == null)) { 
    //        $cta.removeClass("is-on");
    //    } else {
    //        $cta.addClass("is-on");
    //    }
    //}	
};

var searchMobileNav = {

    config: {
        $nav: $(".js-pagination__categories__nav-mobile"),
        select: $(".js-pagination__categories__nav-mobile").find('.select-wrapper__select')
    },

    init: function() {
        this.redirect();
    },

    redirect: function() {
        var $select = this.config.select;

        $select.on('change', function () {
            var url = $(this).find(':selected').data("url");
            window.location = url;
        });	
    }
};

var consultantNav = {
    config: {
        nav: $(".js-consultant-content__nav"),
        navMobile: $(".js-consultant-content__nav-mobile"),
        content: $(".js-consultant-content"),
        header: $(".js-site-header").find(".site-header__fixed")
    },
    init: function () {
        var config = this.config,
            that = this;

        that.setDimensions();

        $(window).on("resize", function () {
            that.setDimensions();
        });

        config.nav.waypoint({
            handler: function(direction) {
                if(direction === "down"){
                    $(this.element).addClass("is-sticky")
                    .find(".js-consultant-content__nav__list").css({
                        top: config.header.height() + 40
                    });

                } else if(direction === "up"){
                    $(this.element).removeClass("is-sticky");				
                }
            },
            offset: function () {
                return config.header.height();
            }
        });

        config.content.waypoint({
            handler: function (direction) {
                if(direction === "down"){
                    config.nav.addClass("is-stuck-to-bottom");
                } else if(direction === "up"){
                    config.nav.removeClass("is-stuck-to-bottom");
                }
            },
            offset: function () {
                return (400 - this.element.clientHeight);
            }
        });

        config.nav.on("click", "a", function (e) {
            e.preventDefault();
            id = $(this).attr("href");
            $("body, html").animate({"scrollTop": $(id).offset().top - 130}, 500);
        });

        config.navMobile.find("select").on("change", function (e) {
            e.preventDefault();
            id = $(this).val();

            if ($(id).length > 0) {
                $("body, html").scrollTop($(id).position().top);
            }			
        });

        if (config.content.find(".consultant-content__section").length > 0) {
            config.content.find(".consultant-content__section").waypoint({
                handler: function(direction) {

                    id = '#'+this.element.id;

                    config.nav.find("a.is-on").removeClass("is-on");
                    if(direction === "down"){
                        config.nav.find("a[href="+id+"]").addClass("is-on");
                    } else if(direction === "up"){
                        config.nav.find("a[href="+id+"]").parents("li").prev().find("a").addClass("is-on");
                    }
                },			
                offset: function () {
                    return config.header.height() + 50;
                }
            });
        }
    },
    setDimensions: function () {

        var config = this.config,
            $navList = config.nav.find(".js-consultant-content__nav__list");

        if (config.nav.length > 0 && $navList.length > 0) {	

            config.nav.height("auto");
            config.nav.height(config.content.height());

            $navList.css({
                left: config.nav.position().left, 
                width: config.nav.width(),
            });
        }
    }

};

var topMenu = {
    config: {
        $menu: $(".js-site-header__top-menu")
    },
    init: function () {
        var c = this.config,
            pageFound = false;

        c.$menu.find(".js-site-header__top-menu__item").each(function () {
            var path = window.location.href;
            path = path.substring(path.indexOf("//"));
            if (path.indexOf($(this).children("a").attr("href")) === 0) {
                $(this).addClass("is-selected");
                pageFound = true;
            }
        });

        if(!pageFound){
            if($("body").hasClass("body--hospital")){
              //  $(".js-site-header__top-menu__item--select-hospital").addClass("is-selected");
            } else {
              //  $(".js-site-header__top-menu__item--main-website").addClass("is-selected");
            }
        }
    }
};

var telephoneLinks = {
    init: function () {
        $("a[href^=tel]").on("click", function (e) {
            if( !(/Android|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ) {
                e.preventDefault();
            }
        });
    }
};


var iosSelectFix = {

    init: function () {

        // iOS hack: Add an optgroup to every select in order to avoid truncating the content
        var windowWidth = getWindowWidth().width;
        var isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIos && windowWidth < 600) {
            var selects = document.querySelectorAll("select");
            for (var i = 0; i < selects.length; i++ ){
                selects[i].appendChild(document.createElement("optgroup"));
            }
        }
    }
};

//CONSULTANT BY SPECIALTY
var tooltip = {

    init: function () {

        $(document).bind('click', function (e) {
            //var $target = e.toElement || e.originalEvent.target || e.relatedTarget || e.target || function () { throw "Failed to attach an event target!"; }
            if (!$(e.target).closest('.tooltip').length && !$(e.target).closest('.toggle-tt').length) {
                $(".tooltip").removeClass('fade in active');
            }
        });
               
        $(".toggle-tt").on("click", function () {    
          $(".tooltip" ).removeClass('fade in active');
          $( this ).parent('li').find( ".tooltip" ).toggleClass('fade in active');
         
        });

        $('[id^="close"]').on('click', function(){
            $(this).parent(".tooltip").removeClass('fade in active');            
          });

       if ($('.specialityFilter').length > 0){
            if ($('.specialityFilter input:checked')[0].id == "s-option") {
            tooltip.select("specialty");    
            } 
        }

         $("#t-option").on('click', function(){
             tooltip.select("treatment");
           });

        $("#s-option").on('click', function(){
            tooltip.select("specialty");
        });
    },
   select: function (option) {
        if (option == "specialty") {           
            $('.finder__cell.speciality').css('display', 'inline-block');              
            $('.finder__cell.treatment').css('display', 'none');
            var isTreatmentSet = helper.getQueryStringByName("filterByTreatment");
            if (isTreatmentSet) {
                $(".js-filter-dropdown__user-selection[name*='treatments']").attr('value', '');
            }
        }

        else {            
            $('.finder__cell.treatment').removeAttr('style');                    
            $('.finder__cell.speciality').css('display', 'none');
            var isSpecialitySet = helper.getQueryStringByName("filterBySpeciality");
            if (isSpecialitySet) {
                $(".js-filter-dropdown__user-selection[name*='speciality']").attr('value', '');
            }
        }
    }
};


var datepicker = {

    init: function () {

        $('#date').attr('readonly', true);
          try{
                var disabledDays = $("#date").data("blocked-dates").split(","); 
            }  
            catch(err) {
                //error
            }          
        
        $('#date').datepicker({
            minDate : 0,
            dateFormat: 'yy-mm-dd', 
            onClose: function () {
                $(this).parsley().validate();

                datepicker.checkAppointmentTimes();
            },
            beforeShowDay: function(date){
            var day = date.getDay();
            var string = jQuery.datepicker.formatDate('yy-mm-dd', date);
            var isDisabled = ($.inArray(string, disabledDays) != -1);
        
            return [!isDisabled];

            }}); 
    },
    checkAppointmentTimes: function () {

        $("#select-time option[value*='Morning']").prop('disabled', false);
        $("#select-time option[value*='Afternoon']").prop('disabled', false);
        $("#select-time option[value*='Evening']").prop('disabled', false);

        var nodeid = $('#hidden-node-id').text();
        var day = $('#date').val();
        
        $.ajax({
            type: "POST",
            url: apilocation + 'EnquiryFormApi/BlockedTimes',
            data: JSON.stringify({ "NodeId": nodeid, "Date" :day}),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).done(function (data) {
            if (data.length > 0) {
                if (data[0] == 'True') {
                    $("#select-time option[value*='Morning']").prop('disabled', true);
                }
                if (data[1] == 'True') {
                    $("#select-time option[value*='Afternoon']").prop('disabled', true);
                }
                if (data[2] == 'True') {
                    $("#select-time option[value*='Evening']").prop('disabled', true);
                }
            }
        });
    }
};



var clickTocall = {

    init: function () {

        $("#clickToCall .call-to-action__link--callback").on("click", function () {
            //gaHelper.trackEvent("Call to Action", "Mobile Call to Action phone was clicked", $(this).html(), undefined, true);
        });

        $("#clickToCall .call-to-action__link--enquiry").on("click", function () {
            //gaHelper.trackEvent("Call to Action", "Mobile Call to Action enquiry was clicked", 'Make enquiry', undefined, true);
        });

    } 
}

var callToActionFullwidth = {
    init: function () {
        $(".js-call-to-action__phone").on("click", function () {
            //gaHelper.trackEvent("Call to Action", "Call to Action phone was clicked - full width", $(this).html(), undefined, true);
        });

        $(".js-call-to-action__submit").on("click", function () {
            //gaHelper.trackEvent("Call to Action", "Enquire button was clicked", 'Make enquire - full width', undefined, true);
        });

    }
}

var footerTracking = {
    init: function () {
        this.globalItems();
    },
     
    globalItems: function () {
        $(".js-socialmedia-facebook").on("click", function () {
            gaHelper.trackEvent("Social Media Button Clicked - Facebook", "Click", "Current URL: " + window.location.href, undefined, true);
        });

        $(".js-socialmedia-twitter").on("click", function () {
            gaHelper.trackEvent("Social Media Button Clicked - twitter", "Click", "Current URL: " + window.location.href, undefined, true);
        });

        $(".js-socialmedia-linkedin").on("click", function () {
            gaHelper.trackEvent("Social Media Button Clicked - linkedin", "Click", "Current URL: " + window.location.href, undefined, true);
        });

        $(".js-socialmedia-youTube").on("click", function () {
            gaHelper.trackEvent("Social Media Button Clicked - youTube", "Click", "Current URL: " + window.location.href, undefined, true);
        });

        $(".js-socialmedia-instagram").on("click", function () {
            gaHelper.trackEvent("Social Media Button Clicked - instagram", "Click", "Current URL: " + window.location.href, undefined, true);
        });
    }
}

var locationsCTA = {
     
    track: function (name, href) {        
        //gaHelper.trackEvent("Find us page, CTA", "Click", "Current URL: " + window.location.href + ", Name: " + name + ", Link: " + href, null, true);
    }

};
 
var consultantTracking = {
    init: function () {
        this.globalItems();
    },
    globalItems: function () {
        $(".js-private-secretary-email").on("click", function () {
            var text = $(this).text();            
            //gaHelper.trackEvent("Private Secretary Email Link", "Click", "Current URL: " + window.location.href + " - Email = " + text, undefined, true);
        });
    }
};

var treatmentPickerWithThreeColumns = {
    init: function () { 
        $(".js_treatment-picker-three-columns__content__toggle").hide();
        this.globalItems();
    },
    globalItems: function () {
        $(".js_treatment-picker-three-columns__content__see-more").on("click", function (e) {
            e.preventDefault();
            $(".treatment-picker-three-columns__content__see-more").hide();
            $(".js_treatment-picker-three-columns__content__toggle").show();
            var featGridItem = $('.feature-grid__item__text');
            featGridItem.matchHeight();
            gaHelper.trackEvent("Homepage see more treatments", "Click", "Open", undefined, true);
        });
    }
};

var homepageGallery = {
    init: function () {
        
        function getCurrentIndex() {
            return parseInt($('.slick-current').attr('data-slick-index'), 10) + 1;
        }

        function sliderClickEvent(idx) {
            if (idx) {
                //gaHelper.trackEvent("New homepage slider", "Click", "Slide " + idx, undefined, true);
            }
            else {
                //gaHelper.trackEvent("New homepage slider", "Click", "Slide " + getCurrentIndex(), undefined, true);
            }
        }

        $('.homepage-gallery').slick({
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: false
        });

        // hide in case we only have 1 slide
        $('.peek-underlay').hide();

        // previous button
        $('.slick-prev').mouseover(function () { 
            $('.peek-wrapper.prev .peek-underlay').show();
            $('.peek-wrapper.prev .peek-text').stop().addClass('shown');
        });

        $('.slick-prev').mouseout(function () {
            $('.peek-wrapper.prev .peek-text').stop().removeClass('shown');
        });

        $('.slick-prev').on('click', function () {
            $('.peek-wrapper.prev .peek-text').stop().removeClass('shown');
            $('.peek-wrapper.prev .peek-underlay').hide();
            sliderClickEvent();
        });

        // next button
        $('.slick-next').mouseover(function () {
            $('.peek-wrapper.next .peek-underlay').show();
            $('.peek-wrapper.next .peek-text').stop().addClass('shown');
        });

        $('.slick-next').mouseout(function () {
            $('.peek-wrapper.next .peek-text').stop().removeClass('shown');
        });

        $('.slick-next').on('click', function () {
            $('.peek-wrapper.next .peek-text').stop().removeClass('shown');
            $('.peek-wrapper.next .peek-underlay').hide();
            sliderClickEvent();
        });

        $('.homepage-gallery .slick-dots').after().on('click', function () {
            $('.homepage-gallery').slick('slickPause');
        });

        $('.slick-dots li button').on('click', function () {
            sliderClickEvent($(this).text());
        });
     }
};


var treatmentCarousel = {
    init: function () {
        
        function getCurrentIndex() {
            return parseInt($('.slick-current').attr('data-slick-index'), 10) + 1;
        }

        function sliderClickEvent(idx) {
            if (idx) {
                //gaHelper.trackEvent("Treatment carousel slider", "Click", "Slide " + idx, undefined, true);
            }
            else {
                //gaHelper.trackEvent("Treatment carousel slider", "Click", "Slide " + getCurrentIndex(), undefined, true);
            }
        }

        $('.treatment-carousel').slick({
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 6000,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
     }
};

var makeEnquiry = {
    init: function () {
       
        //$('.contact-us-bar__inner__enquire-text-container__enquire-link a').on('click', function () {
        //    gaHelper.trackEvent("Button", "Click", "Current URL: " + window.location.href + ", Name: Make an enquiry, Link: " + $(this).attr('href'), undefined, true);
        //});

    }
};

var globalNotification = {
    init: function () {
        $('.panel-title').on('click', function (e) {
            e.preventDefault();

            $('#collapseTwo').toggleClass('toggleDiv');
        });
    }
}


var COBPSConsultant= {

    init: function () {
        
        $(".consultant-content__section__practising-at .viewTreatments a").on("click", function (e) {

            e.preventDefault();

            var accordionName = $(this).attr('href').replace("#", "");
            var accordionHeader = $("a[name=" + accordionName + "]");

            var $content = $(accordionHeader).parents(".js-accordion").find(".js-accordion__content");
            $(accordionHeader).addClass("is-on");
            $content.addClass("is-on");
            //gaHelper.trackEvent("Accordion", "Open", $(this).text(), null, true);

            $('html, body').animate({ scrollTop: $(accordionHeader).offset().top - 100 }, 'slow');
        });


        $(".consultant-header__text a.makeAnEnquiry").on("click", function (e) {
            e.preventDefault();
            var makeEnquiryForm = $("a[name=consultantInpageEnquiryForm]"); 
            $('html, body').animate({ scrollTop: $(makeEnquiryForm).offset().top - 100 }, 'slow');
        });



    }
}

var hospitalTreatmentConsultantFinder = {
    init: function () {
        this.globalItems();
    },
    globalItems: function () {
        $(".js-hospital-consultant-finder__search__consultants").hide();
        $(".js-hospital-consultant-finder__search__hospital").hide();

        $(".js-hospital-consultant-finder__search__content__consultant--button").on("click", function (e) {
            e.preventDefault();
            hospitalTreatmentConsultantFinder.showConsultantsFinder();
            gaHelper.trackEvent("I want to slider", "Click", 'Find a consultant open', null, true);
        });

        $(".js-hospital-consultant-finder__search__content__hospital--button").on("click", function (e) {
            e.preventDefault();
            hospitalTreatmentConsultantFinder.showHospitalFinder();
            gaHelper.trackEvent("I want to slider", "Click", 'Find a hospital open', null, true);
        });

        $(".js-hospital-consultant-finder__search__header--close").on("click", function (e) {
            e.preventDefault();
            hospitalTreatmentConsultantFinder.reset();
        });     

        $(".js-hospital-consultant-finder__search__hospital__next").on("click", function (e) {
            e.preventDefault();
            hospitalTreatmentConsultantFinder.showConsultantsFinder();
            gaHelper.trackEvent("I want to slider", "Click", 'Find a consultant open next', null, true);
        });

        $(".js-hospital-consultant-finder__search__consultant__prev").on("click", function (e) {
            e.preventDefault();
            hospitalTreatmentConsultantFinder.showHospitalFinder();
            gaHelper.trackEvent("I want to slider", "Click", 'Find a hospital open next', null, true); 
        });

        $(".js-hospital-consultant-finder__search__consultants--show").show();
    },
    showConsultantsFinder: function () {
        $(".js-hospital-consultant-finder__search__main").hide();
        $(".js-hospital-consultant-finder__search__consultants").show();
        $(".js-hospital-consultant-finder__search__hospital").hide();
    },
    showHospitalFinder: function () {
        $(".js-hospital-consultant-finder__search__main").hide();
        $(".js-hospital-consultant-finder__search__consultants").hide();
        $(".js-hospital-consultant-finder__search__hospital").show();
    },
    reset: function () {
        $(".js-hospital-consultant-finder__search__consultants").hide();
        $(".js-hospital-consultant-finder__search__hospital").hide();
        $(".js-hospital-consultant-finder__search__main").show();
    }
};


var searchOverlay = {

    init: function () {

        var that = this;

        let searchOverlay = $('.search-overlay');

        var clearAndHideSuggestions = function () {
            $('#search-overlay-suggestion-list').html('');
            $('.search-overlay-suggestions-container').hide();
        };

        var search = function () {
            let query = $('#search-overlay-text-input').val();
            let origin = $('#search-overlay-location-input-geo').val();

            if (query.length > 0) {
                window.location.href = '/search/?query=' + encodeURIComponent(query) + '&origin=' + encodeURIComponent(origin);
            }
        };

        var locationLookupSetup = function () {

            $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyALpZboQbieD8FePSqE0JK2LdTvHlnAZy4&libraries=places', function () {

                const lookup = document.querySelector('.location-lookup-input');

                const locationOptions = {
                    fields: ["formatted_address", "geometry", "name"],
                    strictBounds: false,
                    componentRestrictions: { 'country': 'gb' },
                    types: ['geocode']
                };

                let autocomplete = new google.maps.places.Autocomplete(lookup, locationOptions);

                autocomplete.addListener("place_changed", function () {
                    let place = autocomplete.getPlace();
                    let latlong = place.geometry.location;
                    $('#' + lookup.id + '-geo').val(latlong.lat() + ';' + latlong.lng());
                });

            });


        };

        var requestAutocompleteResults = function (query, success) {

            this.domain = $("#funnelbackDomain").val();
            const col = $("#funnelbackCollection").val();
            this.profile = "/s/search.json?profile=spire-website";
            this.suggestProfile = "/s/suggest.json?profile=spire-website";
            this.collection = "collection=" + col;
            this.searchPathUrl = "/search";


            let url = this.domain + this.suggestProfile + "&" + this.collection + "&fmt=json++&alpha=0.5&show=10&sort=0&partial_query=" + query;
            $.ajax({
                type: "GET",
                url: url,
                success: success
            });
        }

        locationLookupSetup();

        $('#search-overlay-close').on('click', function () {
            searchOverlay.hide();
            $('body').css('overflow', bodyOverflow);

            $('#search-overlay-text-input').val('');
            $('#search-overlay-location-input').val('');
            $('#search-overlay-location-input-geo').val('');
            clearAndHideSuggestions();
        });

        $('.open-overlay-search-button').on('click', function () {
            searchOverlay.show();
            let body = $('body');
            bodyOverflow = body.css('overflow');
            body.css('overflow', 'hidden');
        });

        $('#search-overlay-submit').on('click', function () {
            search();
        });

        $('#search-overlay-text-input').on('input', function () {

            requestAutocompleteResults($(this).val(), function (data) {
                let list = $('#search-overlay-suggestion-list');

                if (data.length < 1) {
                    that.clearAndHideSuggestions();
                    return;
                }

                $('.search-overlay-suggestions-container').show();

                list.html('');

                for (let i = 0; i < data.length; i++) {
                    list.append(`<li>${data[i].disp}</li>`);
                    if (i === 8) {
                        break;
                    }
                }

            });

        });

        $('#search-overlay-suggestion-list').on('click', 'li', function () {
            let input = $('#search-overlay-text-input');
            input.val($(this).text());
            input.trigger('input');
        });

        $('#search-overlay-text-input').on('keydown', function (e) {
            if (e.keyCode === 13) {
                that.search();
            }
        });

        $('#search-overlay-location-mylocation').on('click', function () {
            let setLocation = function (position) {
                $('#search-overlay-location-input').val('My Location');
                $('#search-overlay-location-input-geo').val(position.coords.latitude + ';' + position.coords.longitude)
            }

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(setLocation);
            }
        });

        

    }
};


function addCommas(nStr) {
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

function formatToPercentage(amount) {
    return parseFloat(amount * 100).toFixed(1) + "%";
}

function formatToCurrency(amount) {
    return "£" + addCommas(Math.round(amount));
}

function loadPricingTemplate(element, url, treatmentName, treatmentCode) {

    let bodyElement = document.getElementsByTagName('body')[0];
    var hospitalName = bodyElement.dataset.hospitalName;
    var paymentButtonText = "Find a payment plan that suits you";
    var showFinanceCalculatorLink = document.getElementById("showFinanceCalculatorLink").value;
    var initialConsultantFeeMissingText = document.getElementById("initialConsultantFeeMissingText").value;
    var overridePriceFeeMissingText = document.getElementById("overridePriceFeeMissingText").value;
    var enquiryFormUrl = document.getElementById("enquiryFormUrl").value;

    var template = ``;
    var innerTemplate = ``;

    template +=
        `<div class="grid">
            <div class="grid__cell unit-12-12--desktop unit-12-12--mobile unit-12-12--tablet">`;

    for (var i = 0; i < element.ProceduresApi.length; i++) {
        var item = element.ProceduresApi[i];
        var monthlyPayment, loanAmount, depositTyped, term, representativeAprTyped, totalAmountPayable = 0;
        var procedureName = item.Procedure;
        var procedureCode = item.ProcedureCode;
        var initialConsultantFee = item.InitialConsultation;
        var treatmentPrice = formatToCurrency(item.TreatmentPrice);
        var guidePrice = "from " + formatToCurrency(item.GuidePrice);


        /* Treatment Loan values */
        if (item.TreatmentLoan != null) {
            monthlyPayment = item.TreatmentLoan.MonthlyPayment == null ? "" : formatToCurrency(item.TreatmentLoan.MonthlyPayment);
            loanAmount = formatToCurrency(item.TreatmentLoan.LoanAmount);
            depositTyped = formatToCurrency(item.TreatmentLoan.Deposit);
            term = item.TreatmentLoan.Term + " months";
            representativeAprTyped = formatToPercentage(item.TreatmentLoan.RepresentativeAPR);
            totalAmountPayable = formatToCurrency(item.TreatmentLoan.TotalAmountPayable);
        }        
        /* End treatment loan values  */
        
        
        var financeCalculatorlink = "/patient-information/finance-calculator/?treatmentAmount=" + item.TreatmentPrice + "&treatmentCode=" + treatmentCode + "&treatmentName=" + treatmentName + "&hospitalName=" + hospitalName;

        if (initialConsultantFee == "0" || initialConsultantFee == "-9999") {
            initialConsultantFee = initialConsultantFeeMissingText;
        }
        else {
            initialConsultantFee = "from " + formatToCurrency(initialConsultantFee);
        }

        if (item.RateCardPrice != 0 && item.RateCardPrice != -9999
            && item.ConsultantPrice != 0 && item.ConsultantPrice != -9999
            && item.AnaesthetistPrice != 0 && item.AnaesthetistPrice != -9999) {

            innerTemplate += `
        <table class="procedure-price-content-table" data-procedure-code="${procedureCode}">
            <thead>
                <tr>
                    <th scope="col" colspan="3" class="procedure-price-content__caption"><span>${procedureName}</span></th>
                    <th scope="col"><label id="responsive-label2"></label></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="3">Initial consultation</td>
                    <td class="procedure-price-content__rightcell">${initialConsultantFee}</td>
                </tr>
                <tr>
                    <td colspan="3">Treatment</td>
                    <td class="procedure-price-content__rightcell">${treatmentPrice}</td>
                </tr>
                <tr>
                    <td colspan="3">Aftercare</td>
                    <td class="procedure-price-content__rightcell">Included</td>
                </tr>
                <tr class="procedure-price-content__total">
                    <td colspan="3">From price</td>
                    <td class="procedure-price-content__rightcell">${guidePrice}</td>
                </tr>`;

            if (monthlyPayment.length > 0) {
                innerTemplate += `<tr class="procedure-price-content__total">
                        <td colspan="3">
                            <span>Monthly treatment price</span><br />
                            <span style="font-weight: normal; font-size: 0.7em">(Loan applicable to the treatment cost and excludes the initial consultation)</span>
                        </td>
                        <td class="procedure-price-content__rightcell" valign="top">${monthlyPayment}</td>
                    </tr>`;
            }

            innerTemplate += `</tbody>
            <tfoot>
                <tr class="procedure-price-content__calculatorlink">
                    <td colspan="4">
                        <a class="button button--purple-rounded button--extra-spacing" href="${financeCalculatorlink}">${paymentButtonText}</a>
                    </td>
                </tr>
            </tfoot>
        </table>

        <div class="grid">
            <div class="grid__cell unit-6-12--desktop unit-12-12--mobile">
                <table class="procedure-price-content--plain">
                    <tr>
                        <td colspan="4">
                            <strong>Representative example:</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">Loan amount</td>
                        <td class="procedure-price-content__rightcell">${loanAmount}</td>
                    </tr>
                    <tr>
                        <td colspan="3">Deposit</td>
                        <td class="procedure-price-content__rightcell">${depositTyped}</td>
                    </tr>
                    <tr>
                        <td colspan="3">Loan period</td>
                        <td class="procedure-price-content__rightcell">${term}</td>
                    </tr>
                    <tr>
                        <td colspan="3">Representative APR</td>
                        <td class="procedure-price-content__rightcell">${representativeAprTyped}</td>
                    </tr>
                    <tr>
                        <td colspan="3">Total amount repayable</td>
                        <td class="procedure-price-content__rightcell">${totalAmountPayable}</td>
                    </tr>
                </table>
            </div>

        </div>`;

        }
    }

    if (innerTemplate == "") {
        innerTemplate += `<p><b>` + overridePriceFeeMissingText + `</b></p>`;
    }

    innerTemplate +=
            `<div class="grid__cell unit-12-12--desktop unit-12-12--mobile unit-12-12--tablet right">
                <a class="button button--mint-rounded button--extra-spacing" href="${url}">View Treatment</a>
                <a class="button button--green-rounded button--extra-spacing" href="${enquiryFormUrl}" target="_blank">Make an enquiry</a>
            </div>`;

    template += innerTemplate + `</div></div>`;

    return template;
};

var pricing = {

    loadPrices() {

        let bodyElement = document.getElementsByTagName('body')[0];
        var hospitalid = bodyElement.dataset.id;
        var that = this;
        var endpoint = apilocation + "treatmentapi/GetCurrentHospitalTreatments?hospitalid=" + hospitalid;
        $(".loading").addClass("show");

        $.ajax({
            method: "GET",
            url: endpoint,
            dataType: "json",
        }).done(function (data) {
            //console.log(data);
            var template = ``;
            data.treatments.forEach(element => {

                template += `<section class="accordion js-accordion" data-id='` + element.Id + `' data-url='` + element.Url + `' data-code='` + element.Code + `' data-treatmentname='` + element.TreatmentName + `'>
                    <header class="accordion__header">
                        <a href="#" class="accordion__header__link js-accordion__toggle">
                            <h2 class="heading-small">` + element.TreatmentName
                    + `</h2><div class="accordion__icon"></div>
                        </a>
                    </header>
                    <div class="accordion__content js-accordion__content js-accordion__pricing__content">
                        <div class="accordion__content__text procedure-price-content">
                            <p><b>We can't display a fee for this procedure just now. Call us for a quote.</b></p>
                        </div>
                    </div>
                </section>`;
            });

            $(".js-pricing").html(template);
        })
        .always(function () {
            $(".loading").removeClass("show");
        });
    },

    clickEvents() {
        $("body").on("click", ".js-accordion__toggle", function (e) {

                e.preventDefault();

                var that = this;

                var ison = $(this).hasClass("is-on");

                if (typeof ison !== 'undefined' && ison !== false) {
                    // Get treatment nodeid and pass to page
                    var treatmentId = $(this).closest(".js-accordion").data("id");
                    var treatmentUrl = $(this).closest(".js-accordion").data("url");
                    var enquiryFormUrl = document.getElementById("enquiryFormUrl").value;
                    var treatmentName = $(this).closest(".js-accordion").data("treatmentname");
                    var treatmentCode = $(this).closest(".js-accordion").data("code");
                    var url = apilocation + "pricingapi/GetHospitalTreatmentPrices?id=" + treatmentId;
                    var overridePriceFeeMissingText = document.getElementById("overridePriceFeeMissingText").value;
                    $(".loading").addClass("show");

                    $.ajax({
                        method: "GET",
                        url: url,
                        dataType: "json",
                    }).done(function (data) {
                        var template = ``;
                        if (data.ProceduresApi.length > 0) {
                            //console.log(data);
                            template = loadPricingTemplate(data, treatmentUrl, treatmentName, treatmentCode);
                        }
                        else {
                            template = `<div class="grid">
                                          <div class="grid__cell unit-12-12--desktop unit-12-12--tablet unit-12-12--mobile">
                                            <p><b>${overridePriceFeeMissingText}</b></p>
                                          </div>
                                          <div class="grid__cell unit-12-12--desktop unit-12-12--tablet unit-12-12--mobile right">
                                            <a class="button button--mint-rounded button--extra-spacing" href="${treatmentUrl}">View Treatment</a>
                                            <a class="button button--green-rounded button--extra-spacing" href="${enquiryFormUrl}" target="_blank">Make an enquiry</a>
                                          </div>
                                        </div>`;
                        }
                        $(that).closest(".js-accordion").find(".js-accordion__pricing__content").find(".procedure-price-content").html(template);

                    })
                        .always(function () {
                            $(".loading").removeClass("show");
                        });
                }

            });
    },    

    init() {
        let bodyElement = document.getElementsByTagName('body')[0];
        let dataType = bodyElement.dataset.dtype;
        var apiPricingPage = $(bodyElement).find(".js-pricing");
        if (dataType == "hospitaltreatmentprices" && apiPricingPage.length > 0) {
            this.loadPrices();
            this.clickEvents();
        }
        
    }
};


var livePerson = {

    init: function () {

        $(".call-to-action--floatingButton .chat").on("click", function (e) {
            e.preventDefault();
            $('.LPMcontainer').click();
        });

    }
};

var cookiesSettings = {
    init: function () {

        $(".site-footer__top li a:contains('Cookie settings')").on("click", function (e) {
            e.preventDefault();
            OneTrust.ToggleInfoDisplay();
        });

    }
}

$(function () {    
    callToAction.init();
    externalLinkClass.init();
    //newsFilterSelect.init();
    callBackRequest.init();
    noImageHeader.init();
    // scrollArrow.init();
  //  homepageHero.init();
    liquidHero.init();
    iosSelectFix.init();	
    consultantImgCrop.init();
    gallery.init();
    galleryLightbox.init();
    videoLightbox.init();
    stickyHeader.init();
    awards.init();
    siteHeader.init();
    siteHeaderLocations.init();
    siteHeaderEnquiry.init();
    siteHeaderSearch.init();
    siteNav.init();
    searchMobileNav.init();
    matchHeight.init();
    accordion.init();
    tabs.init();
    insurers.init();
    notice.init();
    alphabeticFilter.init();
    dateValidation.init();
    locationMap.init();
    finder.init();
    enquiryForm.init();
    enquiryFormCTA.init();
    filterDropdown.init();
    paginationFilters.init();
    cookies.init();
    cookies.surveyCookies();
    cookies.bannerCookies();
    checkIfWesbiteLeft.init();
    enquiryTabs.init();
    siteNavMobile.init();   
    consultantNav.init();
    siteHeaderTracking.init();
    gpConnectTracking.init();
    ctaTracking.init();
    topMenu.init();
    tooltip.init(); 
    datepicker.init();   
    telephoneLinks.init();
    eventBookingForm.init();
    clickTocall.init();
    consultantTracking.init();
    footerTracking.init();    
    callToActionFullwidth.init();
    homepageGallery.init();
    makeEnquiry.init();
    treatmentPickerWithThreeColumns.init();
    hospitalTreatmentConsultantFinder.init();
    COBPSConsultant.init();
    requestForm.init();
    treatmentCarousel.init();
    globalNotification.init();
    pricing.init();
    searchOverlay.init();
    livePerson.init();
    cookiesSettings.init();
});

//Get all query strings from current URL
function getURLQueryStrings() { 
  var assoc  = {};
  var decode = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); };
  var queryString = location.search.substring(1); 
  var keyValues = queryString.split('&'); 

  for(var i in keyValues) { 
    var key = keyValues[i].split('=');
    if (key.length > 1) {
      assoc[decode(key[0])] = decode(key[1]);
    }
  } 

  return assoc; 
}




